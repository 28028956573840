<template>
  <div id="task" class="row">
    <div class="col-12">
      <template>
        <b-breadcrumb class="small">
          <b-breadcrumb-item :to="{name: 'media_files'}">{{ $t('Projects') }}</b-breadcrumb-item>
          <b-breadcrumb-item :to="{name: 'tasks', params: {media_file_hashid: media_file.hashid}}" v-if="!!media_file.info">{{ media_file.info.title }}</b-breadcrumb-item>
          <b-breadcrumb-item active>{{$t(task.demand)}}: <span class="clock">{{ task.language_code_deepl }}</span> <span> {{ $t('lang.'+task.language_code_deepl) }}</span></b-breadcrumb-item>
        </b-breadcrumb>
      </template>
      <div id="upper-container">
        <div class="mt-1">
          <video 
          id="video-player"
          ref="video-player" 
          :class="settings.playerSize"
          @click="player.playing ? player.pause() : player.play()"
          oncontextmenu="return false"
          >
          </video>
        </div>

        <div id="ysubtitles" class="ml-2" :class="settings.playerSize">
          <!-- <div class="mt-1 mb-2 clickable animeS">{{media_file.info.title}}</div> -->
          <div id="items" style="font-size:0.93em;">
            <div 
            :class="{'text-primary': subtitle.start <= player.currentTime && player.currentTime <= subtitle.end}" 
            v-for="(subtitle, i) in activeSubtitles" :key="i" 
            :href="'#subtitle-'+i" @click="segmentFocused_ = subtitle" 
            v-show="query === '' || new RegExp(query, 'i').test(subtitle.text)"
            >
              <SubtitleIndex :subtitle="subtitle" :i="i"></SubtitleIndex>
            </div>
          </div>
        </div>
        <div id="search" class="ml-2 mb-2 mr-1 d-flex justify-content-end">
          <div class="position-absolute pl-2 pb-2" style="background-color: rgba(255,255,255,0.3);">
            <form class="form-inline">
              <input id="query" type="text" class="form-control form-control-sm" autocomplete="off" size="10" v-model="query" trim placeholder="Filter" style="font-weight: bold; background-color: rgba(255,255,255,0.5);">
            </form>
          </div>
        </div>
        <div id="misc" class="ml-3">


          <!-- <div class="mb-1">
            <vue-online-offline @online="isOnline" @offline="isOffline" style="font-size: 1.6rem" v-b-tooltip.hover.left title="インターネット接続">
              <div slot="online"><i class="bi bi-wifi"></i></div>
              <div slot="offline" class="text-danger"><i class="bi bi-wifi-off"></i></div>
            </vue-online-offline>
          </div> -->
          <!-- <div 
          v-b-tooltip.hover.left 
          title="Planモードチェンジ" 
          class="mb-5 clickable animeDock"
          > 
            <div class="text-center clock" style="margin-bottom: -10px">P</div>
          </div> -->


          <div 
          v-b-tooltip.hover.left 
          title="保存" 
          class="mb-3 clickable animeDock" @click="save()" 
          :class="{'text-danger': subtitlesNotSavedYet_, 'text-success': savingNow_}"
          v-shortkey="{windows: ['ctrl', 's'], mac: ['meta', 's']}" @shortkey="save()"
          >
            <div style="margin-bottom: -10px"><i class="bi bi-save" style="font-size: 1.6rem"></i></div>
            <div class="text-center"><span class="clock" style="font-size: 0.6rem">SAVE</span></div>
          </div>

          <!-- <div 
          v-b-tooltip.hover.left 
          title="字幕プレビュー" 
          class="mb-5 clickable animeDock" @click="setSubtitleCues()" 
          :class="{'text-danger': subtitlesNotSetCCYet_, 'text-success': settingCCNow_}"
          v-shortkey="{windows: ['ctrl', 'r'], mac: ['meta', 'r']}" @shortkey="setSubtitleCues()"
          >
            <div style="margin-bottom: -10px"><i class="bi bi-badge-cc" style="font-size: 1.6rem"></i></div>
          </div> -->
          <div v-shortkey="{windows: ['ctrl', 'r'], mac: ['meta', 'r']}" @shortkey="subtitles2sentences(subtitles)"></div>
          <div v-b-toggle.trash v-b-tooltip.hover.left title="ごみ箱の中" class="mb-4 animeDock">
            <div><i class="bi bi-trash" style="font-size: 1.6rem"></i></div>
            <div class="text-center small" style="margin-top: -9px">{{ inactiveSubtitles.length }}</div>
          </div>
          <b-sidebar
            id="trash"
            no-header
            backdrop
            shadow
            right
            width="500px"
          >
            <div class="px-3 py-2 small">
              <!-- <transition-group> -->
                <div class="subtitle d-flex flex-row mb-2" v-for="(subtitle) in inactiveSubtitles" :key="subtitle.uuid">
                  <!-- <div class="mx-2"><i class="bi bi-arrow-bar-left" style="font-size: 1.5rem"></i></div> -->
                  <div class="ml-1 d-flex flex-column clickable trashed" 
                  @click="subtitle.untrash(); segmentFocused_ = subtitle" 
                  v-b-tooltip.hover.left :title="'クリックでごみ箱から戻す'">
                    <div><span class="clock">{{subtitle.start|mcsscff}} - {{subtitle.end|mcsscff}}</span></div>
                    <!-- <span :class="'speaker-'+subtitle.speaker" v-if="subtitle.speaker < 20"><i class="bi bi-person-fill"></i>{{ settings.speakerCnames[subtitle.speaker] ? settings.speakerCnames[subtitle.speaker] : subtitle.speaker }}</span> -->
                    <div class="text text-truncate" v-if="subtitle.text">{{subtitle.text}}</div>
                    <div class="text text-truncate" v-else><i>No text</i></div>
                    <!-- <div>uuid: {{ subtitle.uuid }}</div> -->
                  </div>
                </div>
              <!-- </transition-group> -->
            </div>
          </b-sidebar>

          <!-- <div v-b-toggle.readme v-b-tooltip.hover.left title="使い方" class="mb-1 animeDock"><i class="bi bi-book" style="font-size: 1.6rem"></i></div>
          <b-sidebar
            id="readme"
            no-header
            backdrop
            shadow
            right
            width="500px"
          >
            <div class="px-3 py-3">
              
            </div>
          </b-sidebar> -->

          <div v-b-toggle.shortcut v-b-tooltip.hover.left title="ヘルプとショートカットキー" class="mb-1 animeDock"><i class="bi bi-command" style="font-size: 1.6rem"></i></div>
          <b-sidebar
            id="shortcut"
            no-header
            backdrop
            shadow
            right
            width="500px"
          >
            <div class="px-3 py-2">
              <div><strong>保存：</strong><br><code>control(あるいはcommand) + S</code></div>
              <!-- <div><strong>字幕の反映：</strong><br><code>control(あるいはcommand) + R</code></div> -->
              <div><strong>字幕セグメントの分割：</strong><br>字幕セグメントの字幕テキスト入力エリアで、<code>shift + Enter(あるいはReturn)</code>。カーソル位置の前後のテキストで2つに分割します。</div>
              <div><strong>字幕セグメントの結合：</strong><br>字幕セグメントの字幕テキスト入力エリアの先頭にカーソルを置いて、<code>shift + Backspace(あるいはDelete)</code>。ひとつ前の字幕セグメントと結合します。</div>
              <div><strong>文末記号の無効化：</strong><br>字幕テキスト入力エリアの文章について無効化したい文末記号の直前にバックスラッシュ'<code>\</code>'（例: 小数点の'0\.99'）を入力します。</div>
            </div>
          </b-sidebar>


          <div v-b-toggle.settings v-b-tooltip.hover.left title="設定" class="mb-1 animeDock"><i class="bi bi-gear" style="font-size: 1.6rem"></i></div>
          <b-sidebar
            id="settings"
            no-header
            backdrop
            shadow
            right
            width="500px"
          >
            <div class="px-3 py-3">
              <form class="form-inline">


                <div class="form-group mb-3" v-if="task.demand==='transcription'">
                  <div for="pilotLanguage" class="mr-4"><strong>文字起こし言語:</strong></div><br>
                  <b-form-select v-model="task.language_code_deepl" maxlength="10" size="sm">
                    <b-form-select-option :value="language.code_deepl" v-for="language in languages" :key="language.code_deepl">{{language.code_deepl}} {{ $t('lang.'+language.code_deepl) }}</b-form-select-option>
                  </b-form-select>
                  <b-button size="sm" variant="outline-secondary" @click="$router.go({path: $router.currentRoute.path, force: true})
  ">適用</b-button>
                </div>

                <div class="form-group mb-3">
                  <label for="charLimitPerSegment" class="mr-4"><strong>字幕セグメントあたりの文字数上限:</strong></label>
                  <b-form-input
                  id="charLimitPerSegment"
                  v-model.number="settings.charLimitPerSegment"
                  :state="!isNaN(settings.charLimitPerSegment) && 0 < settings.charLimitPerSegment && settings.charLimitPerSegment < 500"
                  aria-describedby="input-live-help input-live-feedback"
                  placeholder=""
                  maxlength="3"
                  :number="true"
                  size="sm"
                  style="width: 5rem"
                  @keyup.up.prevent="settings.charLimitPerSegment++"
                  @keyup.down.prevent="settings.charLimitPerSegment--"
                  @keyup.left.prevent="settings.charLimitPerSegment=1"
                  @keyup.right.prevent="settings.charLimitPerSegment=99"
                  trim
                  v-b-tooltip.hover title="数字あるいは方向キーで入力できます"
                  autocomplete="off"
                  ></b-form-input><small class="ml-2">字/字幕セグメント</small>
                </div>
                <div class="form-group mb-3">
                  <label for="speedLimitPerSec" class="mr-4"><strong>字幕スピード制限:</strong></label>
                  <b-form-input
                  id="speedLimitPerSec"
                  v-model.number="settings.speedLimitPerSec"
                  :state="!isNaN(settings.speedLimitPerSec) && 0 < settings.speedLimitPerSec && settings.speedLimitPerSec < 100"
                  aria-describedby="input-live-help input-live-feedback"
                  placeholder=""
                  maxlength="2"
                  :number="true"
                  size="sm"
                  style="width: 4rem"
                  @keyup.up.prevent="settings.speedLimitPerSec++"
                  @keyup.down.prevent="settings.speedLimitPerSec--"
                  @keyup.left.prevent="settings.speedLimitPerSec=1"
                  @keyup.right.prevent="settings.speedLimitPerSec=99"
                  trim
                  v-b-tooltip.hover title="数字あるいは方向キーで入力できます"
                  autocomplete="off"

                  ></b-form-input><small class="ml-2">字/秒</small>
                </div>
                <div class="form-group mb-3">
                  <label for="fullStop" class="mr-4"><strong>文末記号:</strong></label>
                  <b-form-input
                  id="fullStop"
                  v-model="settings.fullStop"
                  placeholder=""
                  maxlength="10"
                  size="sm"
                  style="width: 10rem"
                  trim
                  autocomplete="off"
                  readonly
                  ></b-form-input>
                 <small>(現在変更不可)</small>
                </div>


                
                <div class="form-group mb-3" v-if="task.demand==='transcription'">
                  <div for="pilotLanguage" class="mr-4"><strong>翻訳テスト用言語:</strong></div><br>
                  <b-form-select v-model="settings.pilotTranslationLanguageCode" maxlength="10" size="sm">
                    <b-form-select-option :value="language.code_deepl" v-for="language in languages" :key="language.code_deepl">{{ language.code_deepl }} {{ $t('lang.'+language.code_deepl) }}</b-form-select-option>
                  </b-form-select>
                  <b-button size="sm" variant="outline-secondary" @click="$router.go({path: $router.currentRoute.path, force: true})
  ">適用</b-button>
                </div>
                <!-- <div class="form-group mb-3">
                  <label for="speaker" class="mr-4"><strong>話者とテロップの別名:</strong></label>
                  <b-form-input
                  id="speaker"
                  v-model="settings.fullStop"
                  placeholder=""
                  maxlength="10"
                  size="sm"
                  style="width: 10rem"
                  trim
                  autocomplete="off"
                  ></b-form-input>
                </div> -->

                <!-- <div class="form-group mb-3">
                  <label for="playerSize" class="mr-4"><strong>動画サイズ:</strong></label>
                  <select id="playerSize" class="custom-select custom-select-sm" v-model="settings.playerSize">
                    <option value="lg">大</option>
                    <option value="md">中</option>
                    <option value="sm">小</option>
                  </select>
                </div> -->
              </form>
            </div>
          </b-sidebar>



          <!-- <div v-b-toggle.metainfo v-b-tooltip.hover.left title="メタ情報" class="mb-1 animeDock"><i class="bi bi-info-circle" style="font-size: 1.6rem"></i></div>
          <b-sidebar
            id="metainfo"
            no-header
            backdrop
            shadow
            right
            width="500px"
          >
            <div class="ml-3" style="word-wrap: break-word;" v-if="media_file.info">
              <div>
                <small>チャンネル</small>
              </div>
              <div class="small p-1">{{media_file.info.channel}}</div>
              <div>
                <small>タイトル</small>
              </div>
              <div class="small p-1">{{media_file.info.title}}</div>
              <div>
                <small>時間</small>
              </div>
              <div class="small p-1">{{media_file.info.duration_string}}</div>
              <div class="mt-2">
                <small>概要</small>
              </div>
              <div class="small p-1" style="white-space: pre-wrap;">{{media_file.info.description}}</div>
              
            </div>
          </b-sidebar> -->



          <!-- <div v-b-tooltip.hover.left title="download" class="mb-1 animeDock"><i class="bi bi-download" style="font-size: 1.6rem"></i></div> -->

        </div>
      </div>

      <div id="seekbar-scale" class="mt-3">
        <div class="animeM"><span class="clock clickable unselectable" @click="seek(0); player.pause()">{{0|mcsscff}}</span></div>

        <div id="seekbar-controls">
          <div class="mx-4 animeM"><span class="clock clickable unselectable" @click="seek(player.currentTime-60.0)"><i class="bi bi-chevron-double-left"></i>60s</span></div>

          <div class="mx-2 animeM"><span class="clock clickable unselectable" @click="seek(player.currentTime-10.0)"><i class="bi bi-chevron-double-left"></i>10s</span></div>

          <div id="current-time" class="text-center animeM" style="font-size: 1.1rem">
            <span class="clickable clock unselectable" @click="player.playing ? player.pause() : player.play()">{{currentTime|mcsscff}}</span>
            <i class="bi bi-pause-fill clickable unselectable" @click="player.pause()" v-if="player.playing"></i><!--resetPlayer();-->
            <i class="bi bi-play-fill clickable unselectable"  @click="player.play()" v-else></i>
          </div>
          
          <div class="mx-2 animeM"><span class="clock clickable unselectable" @click="seek(player.currentTime+10.0)">10s<i class="bi bi-chevron-double-right"></i></span></div>

          <div class="mx-4 animeM"><span class="clock clickable unselectable" @click="seek(player.currentTime+60.0)">60s<i class="bi bi-chevron-double-right"></i></span></div>
        </div>

        <div class="animeM"><span class="clock clickable unselectable" @click="seek(player.duration); player.pause()">{{player.duration|mcsscff}}</span></div>
      </div>



      <input id="seekbar" type="range" class="input-range" v-model="player.currentTime" min="0.00" :max="player.duration" step="0.01" v-cloak> 
      <!-- data-unit="%"> -->

      <div class="xsubtitles-lane">

        
        <div id="xsubtitles" v-if="activeSubtitles.length > 0">
          

          <!-- loop -->
          <div class="d-flex align-middle" v-for="(subtitle, index0) in activeSubtitles" :key="index0">
            
            <div class="d-flex align-items-center add-segment clickable" 
            @click="addBlankSubtitleAfterCurrSubtitle()" v-b-tooltip.hover.left 
            title="余白に新しい字幕セグメントを追加します" v-if="index0 === 0">
              <div><small><i class="bi bi-plus-circle"></i></small></div>
            </div>

            <div class="subtitle border rounded" >
              
              <div class="d-flex clock bd-highlight segment-header">

                <div class="flex-fill small clickable bd-highlight">
                  <span 
                  @click="segmentFocused2_ = subtitle"
                  v-b-tooltip.hover.left :title="'字幕セグメント #'+(index0+1) + ' ' + subtitle.uuid" :class="{'text-primary': subtitle.start <= player.currentTime && player.currentTime <= subtitle.end}">
                    <i class="bi bi-hash"></i>{{ index0+1 }}
                  </span>
                </div>
                

                <div class="flex-fill text-center clickable unselctable animeDock bd-highlight"
                @click="
                  resetPlayer();
                  playActiveSubtitle_ = subtitle;
                  // const subtitleText = $refs.subtitleText.find((ref) => {
                  //   return ref.$attrs.stkey == subtitle.start;
                  // });
                  // subtitleText.focus();
                  playSegment(subtitle.start, subtitle.end, 3);
                  playActiveSubtitle_ = null;
                ">
                  <small><i class="bi bi-arrow-repeat"></i>3</small>
                </div>

                
                <div class="flex-fill small bd-highlight">
                  <div class="unselectable text-right" @click="subtitle.trash()" v-b-tooltip.hover.right title="この字幕セグメントをごみ箱に入れる">
                    <span class="clickable animeDock"><i class="bi bi-trash"></i></span>
                  </div>
                </div>

              </div>
              
              <div class="start clock d-flex justify-content-between bd-highlight">
                <div class="bd-highlight">
                  <span 
                  class="clickable unselectable" 
                  @click.shift.exact.prevent="subtitle.startPlus(-1.0);resetPlayer();player.pause();seek(subtitle.start)" 
                  @click.alt.exact.prevent="subtitle.startPlus(-0.01);resetPlayer();player.pause();seek(subtitle.start)" 
                  @click.exact.prevent="subtitle.startPlus(-0.10);resetPlayer();player.pause();seek(subtitle.start)"
                  v-b-tooltip.hover title="Alt(あるいはOption)+クリックで0.01s刻み Shift+クリックで1.0s刻み"
                  >
                    <i class="bi bi-caret-left"></i>
                  </span>
                  <span class="clickable unselectable" @click="resetPlayer();seek(subtitle.start)">{{subtitle.start|mcsscff}}</span>
                  <span 
                  class="clickable unselectable" 
                  @click.shift.exact.prevent="subtitle.startPlus(+1.0);resetPlayer();player.pause();seek(subtitle.start)"
                  @click.alt.exact.prevent="subtitle.startPlus(+0.01);resetPlayer();player.pause();seek(subtitle.start)"
                  @click.exact.prevent="subtitle.startPlus(+0.10);resetPlayer();player.pause();seek(subtitle.start)"
                  v-b-tooltip.hover title="Alt(あるいはOption)+クリックで0.01s刻み Shift+クリックで1.0s刻み"
                  >
                  <i class="bi bi-caret-right"></i>
                  </span>
                </div>
                <div class="animeM bd-highlight">
                  <span class="clickable unselectable" 
                  @click="
                  //resetPlayer();
                  playActiveSubtitle_ = subtitle;
                  if(subtitle.start < player.currentTime && player.currentTime < subtitle.end){
                    // if(!player.paused) {
                    if(player.playing){
                      player.pause();
                    }else{
                      player.play();
                    }
                  }else{
                    playSegment(subtitle.start, subtitle.end);
                  }

                  ">
                  <!-- 
                  // const subtitleText = $refs.subtitleText.find((ref) => {
                  //   return ref.$attrs.stkey == subtitle.start;
                  // });
                  // subtitleText.focus();
                  -->
                  <i class="bi bi-play-fill clickable unselectable" v-if="player.paused || playActiveSubtitle_ != subtitle"></i>
                  <i class="bi bi-pause-fill clickable unselectable" v-else></i>{{subtitle.duration|scff}}s
                  </span>
                </div>
                <div class="bd-highlight">
                  <span 
                  class="clickable unselectable" 
                  @click.shift.exact.prevent="subtitle.endPlus(-1.0);resetPlayer();player.pause();seek(subtitle.end)" 
                  @click.alt.exact.prevent="subtitle.endPlus(-0.01);resetPlayer();player.pause();seek(subtitle.end)" 
                  @click.exact.prevent="subtitle.endPlus(-0.10);resetPlayer();player.pause();seek(subtitle.end)"
                  v-b-tooltip.hover title="Alt(あるいはOption)+クリックで0.01s刻み Shift+クリックで1.0s刻み"
                  >
                    <i class="bi bi-caret-left"></i>
                  </span>
                  <span class="clickable" @click="resetPlayer();seek(subtitle.end);player.pause()">{{subtitle.end|mcsscff}}</span>
                  <span 
                  class="clickable unselectable" 
                  @click.shift.exact.prevent="subtitle.endPlus(+1.0);resetPlayer();player.pause();seek(subtitle.end)"
                  @click.alt.exact.prevent="subtitle.endPlus(+0.01);resetPlayer();player.pause();seek(subtitle.end)"
                  @click.exact.prevent="subtitle.endPlus(+0.10);resetPlayer();player.pause();seek(subtitle.end)"
                  v-b-tooltip.hover title="Alt(あるいはOption)+クリックで0.01s刻み Shift+クリックで1.0s刻み"
                  >
                    <i class="bi bi-caret-right"></i>
                  </span>
                </div>
              </div>

              <!-- <div class="d-flex justify-content-between">
                <div>
                  <small><i class="bi bi-person"></i> {{ subtitle.speaker }}</small>    
                </div>
              </div> -->
              <!-- duration によってtruncateの引数を変える。平均的な読みスピードを考慮。 -->
              <!-- <div class="text clickable">{{subtitle.text|truncate(42)}}</div> -->
              <!-- https://qiita.com/bagooon/items/058db1431fc7d02b6aeb -->

              <div v-if="task.demand==='transcription'">
                <TranscriptionSubtitleEditor 
                :subtitle="subtitle" 
                :settings="settings" 
                :task="task" 
                :media_file="media_file" 
                :index0="index0"
                @splitSegment="splitSegment(subtitle, $event)"
                @joinSegments="joinSegments(subtitle, $event)"
                />
              </div>
              <div v-if="task.demand==='translation'">
                <TranslationSubtitleEditor 
                :subtitle="subtitle" 
                :settings="settings" 
                :task="task" 
                :media_file="media_file" 
                :index0="index0"
                @splitSegment="splitSegment(subtitle, $event)"
                @joinSegments="joinSegments(subtitle, $event)"
                />
              </div>

              <div class="mt-2 d-flex justify-content-between" v-if="task.demand==='transcription' && sentences[index0] && sentences[index0].length">
                <div>
                  <small class="subheading">{{ $t('Translation_Test') }}: <span class="clock">{{settings.pilotTranslationLanguageCode}}</span></small>
                </div>
                <!-- <div>
                  <small>
                    <b-form-checkbox
                      v-model="subtitle.ok"
                      class="clock"
                      value="1"
                      unchecked-value="0"
                      switch
                      size="sm"
                    >OK</b-form-checkbox>
                  </small>
                </div> -->
              </div>
              <b-list-group class="mb-2" v-if="task.demand === 'transcription'">
                <b-list-group-item v-for="(sentence, index1) in sentences[index0]" :key="index1">
                  <Sentence 
                  ref="sentence" 
                  :text="sentence.text"
                  :uuid="subtitle.uuid" 
                  :context="getContext(sentence)" 
                  :transcriptionLanguageCode="task.language_code_deepl" 
                  :pilotTranslationLanguageCode="settings.pilotTranslationLanguageCode" 
                  :back="true"
                  v-if="sentence.uuid === subtitle.uuid"
                  />
                </b-list-group-item>
              </b-list-group>

              <b-list-group class="mb-2" v-if="task.demand === 'translation'">
                <b-list-group-item v-for="(sentence, index1) in sentences[index0]" :key="index1">
                  <Sentence 
                  ref="sentence" 
                  :text="sentence.text"
                  :uuid="subtitle.uuid" 
                  :context="getContext(sentence)" 
                  :transcriptionLanguageCode="task.language_code_deepl" 
                  :pilotTranslationLanguageCode="media_file.plan[0].language_code_deepl"
                  :back="false"
                  v-if="sentence.uuid === subtitle.uuid"
                  />
                </b-list-group-item>
              </b-list-group>
              <!-- <div class="clock small" v-show="true">({{ subtitle.uuid }})</div> -->
            </div>


            <div class="d-flex align-items-center add-segment clickable" @click="addBlankSubtitleAfterCurrSubtitle(subtitle)" v-b-tooltip.hover.left title="余白に新しい字幕セグメントを追加する">
              <div><small><i class="bi bi-plus-circle"></i></small></div>
            </div>
          </div>
          <!-- /loop -->
        </div>
        
      </div>

      <pre v-if="false && task.id>0">
        task: {{task|json}}
        settings: {{settings|json}}
      </pre>
      <div v-if="false">
        <div class="text-right small"><span class="clock">{{activeSubtitles.length}} + {{inactiveSubtitles.length}} = {{subtitles.length}}</span></div>
      </div>
    </div>
  </div>
  
</template>

<script>
  import _ from 'lodash';
  import BigNumber  from "bignumber.js";

  // import nestedDraggable from "./infra/nested";
  // import VueOnlineOffline from 'vue-online-offline';

  class Subtitle {

    constructor(start, end, uuid=null, speaker = 0, text = '', text_ex = '', trashed_at = null) {

      this.uuid = uuid ? uuid : Subtitle.generateUuid();
      this.start = String(start);
      this.end = String(end);
      this.speaker = speaker;
      this.text = String(text).replace(/^\s*\n/gm, '');
      // this.src_text = String(src_text).replace(/^\s*\n/gm, '');
      this.text_ex = String(text_ex).replace(/^\s*\n/gm, '');
      this.duration = Subtitle.calcDuration(this.start, this.end);
      this.trashed_at = trashed_at;
      this.ok = false;
      // this.sentences = [];
    }
    trash() {
      this.trashed_at = new Date().getTime();
    }
    untrash() {
      this.trashed_at = null;
    }
    startPlus(floatNum=+0.1) {
      if((BigNumber(this.end).gt(BigNumber(this.start).plus(floatNum)))){
        this.start = BigNumber(this.start).plus(floatNum).toString();
        this.duration = Subtitle.calcDuration(this.start, this.end);
        return;
      }
      // console.log((BigNumber(this.end) > BigNumber(this.start).plus(floatNum)));
    }
    endPlus(floatNum=+0.1) {
      if((BigNumber(this.end).plus(floatNum).gt(BigNumber(this.start)))){
        this.end = BigNumber(this.end).plus(floatNum).toString();
        this.duration = Subtitle.calcDuration(this.start, this.end);
        return;
      }
      // console.log(this.start, this.end);
    }

    okState(charLimitPerSegment = 48, speedLimitPerSec = 20) {
      if(this.text) return null ;
      const ok = this.okStateCharLimitPerSegment(charLimitPerSegment) && this.okStateSpeedLimitPerSec(speedLimitPerSec);
      return ok;
    }

    okStateCharLimitPerSegment(charLimitPerSegment = 48){
      const ok = this.text.length <= charLimitPerSegment 
      && this.text.length >= 0 
      && !(/^\s*\n/gm.test(this.text));
      return ok;
    }

    okStateSpeedLimitPerSec(speedLimitPerSec = 20){
      const ok = this.text.length/(this.end-this.start) <= speedLimitPerSec;
      return ok;
    }


    static calcDuration(start, end){
      return BigNumber(end).plus(-(start)).toString();
    }
    static compare(subtitleA, subtitleB){
      const startA = parseFloat(subtitleA.start);
      const startB = parseFloat(subtitleB.start);
      if(startA < startB) return -1;
      if(startA > startB) return 1;
      return 0;
    }

    static generateUuid() {
        // https://github.com/GoogleChrome/chrome-platform-analytics/blob/master/src/internal/identifier.js
        // const FORMAT: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
        let chars = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".split("");
        for (let i = 0, len = chars.length; i < len; i++) {
            switch (chars[i]) {
                case "x":
                    chars[i] = Math.floor(Math.random() * 16).toString(16);
                    break;
                case "y":
                    chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
                    break;
            }
        }
        return chars.join("");
    }
  }

  import TranscriptionSubtitleEditor from "./TranscriptionSubtitleEditor";
  import TranslationSubtitleEditor from "./TranslationSubtitleEditor";
  import Sentence from "./Sentence";
  import SubtitleIndex from './SubtitleIndex.vue';
  // import Plan from './Plan.vue';

  export default {
    name: 'Task',
    // components: {VueOnlineOffline},
    components: { 
      TranscriptionSubtitleEditor,
      TranslationSubtitleEditor,
      Sentence, 
      SubtitleIndex, 
      // Plan,
    },
    data() {
      return {
        

        player: {},
        settings: {},
        

        media_file: {},
        task: {},

        subtitles:[],
        sentences: [],

        languages: [],

        query: '',

        duration: 0.0,
        currentTime: 0.0,
        
        textTrack: null,
        
        playSegmentProcess_: null,
        playActiveSubtitle_: null,
        subtitlesNotSavedYet_: false,

        savingNow_: false,
        subtitlesNotSetCCYet_: false,
        settingCCNow_: false,
        segmentFocused_: null,
        segmentFocused2_: null,

        translationShowOverlay: false,
      };
    },
    
    methods: {

      debug: (value) => {
        console.debug(value)
      },


      getContext: function(sentence){ 
        const sentences_flatten = this.sentences.flat();
        // console.log(sentences_flatten);
        const fi = sentences_flatten.indexOf(sentence);
        // console.log(fi);
        let context = '';
        if(!!sentences_flatten[fi-1] && sentences_flatten[fi-1].speaker === sentence.speaker){
          context = sentences_flatten[fi-1].text + context;
          // if(context.length > 20) break;// 20文字以上の撮れ高で離脱
          // if(context.length > 0) break;
        }
        // console.log('getContext');
        return context;
      },

      // https://regex101.com/r/BVgg6Y/1
      // https://regex101.com/r/bF3rK7/1
      // https://regex101.com/r/kB7sS1/1
      subtitles2sentences: function(subtitles){
        // https://sunday-morning.app/posts/2020-09-28-js-regexp-variable
        const re_split = new RegExp(String.raw`(?<=[^\\][${this.settings.fullStop}])`, 'g');
        const re_fs = new RegExp(String.raw`^[${this.settings.fullStop}]$`, 'g');
        let flagments = [];
        subtitles.forEach((subtitle, idx)=>{
          let text = !!subtitle.text_ex && subtitle.text_ex.length > 0 ? subtitle.text_ex : subtitle.text;
          let res = [];
          
          text.split(re_split).forEach(t => {
            if(t.match(re_fs)){
              res[res.length - 1] = res.at(-1) + t
            }else{
              res.push(t);
            }
          });
          flagments[idx] = {
            uuid: subtitle.uuid,
            speaker: subtitle.speaker,
            flagments: res,
          }
          flagments.push(res);
        });
        console.log(flagments);
        // return ;

        let speakers = flagments.map(function(f){
          return f.speaker
        }).filter((elem, index, self) => self.indexOf(elem) === index && elem != undefined);

        const language = this.languages.find(l => l.code_deepl === this.task.language_code_deepl);
        const wakachi = language.space_wakachi ? ' ' : '';
        // console.log(language, "'" + wakachi + "'");

        let sentences = [];
        speakers.forEach((sp) => {
          let sentence_text = '';
          let cnt = 0;
          flagments.forEach((fa, idx) => {
            if(fa.flagments === undefined){return;}
            sentences[idx] = sentences[idx] && sentences[idx].length>0 ? sentences[idx] : [];
            if(String(fa.speaker) === String(sp)){
              fa.flagments.forEach((f) => {
                if(f.match(re_split)){
                  sentences[idx].push({
                    uuid:           fa.uuid,
                    cnt:            cnt,
                    speaker:        sp,
                    text:           (sentence_text+wakachi+f).replace(/\\/g, '').replace(/[　]/g, ' ').trim(), 
                    language_code:  this.task.code_deepl,
                  });
                  sentence_text = '';
                  cnt = 0;
                }else{
                  // const spaceWakachi = '';
                  sentence_text += wakachi + f;
                  cnt++;
                }
              }); 
            }
          });
        });
        // console.log(sentences[0]);

        return sentences;
      },


      // subtitles2sentences: function(subtitles){
      //   // console.log('subtitles2sentences!!!!!!');
      //   // console.log(subtitles);
      //   const re = new RegExp('(['+this.settings.fullStop+']+)', 'g');//[^0-9]
      //   // let sentences = [];
      //   let flagments = [];
      //   subtitles.forEach((subtitle, idx)=>{
      //     let text = !!subtitle.text_ex && subtitle.text_ex.length > 0 ? subtitle.text_ex : subtitle.text;
      //     let res = [];
      //     text.split(re).forEach(function(t, idx){
      //       res.push(t);
      //     });
      //     flagments[idx] = {
      //       uuid: subtitle.uuid,
      //       speaker: subtitle.speaker,
      //       flagments: res,
      //     }
      //     flagments.push(res);
      //   });


      //   let speakers = flagments.map(function(f){
      //     return f.speaker
      //   }).filter((elem, index, self) => self.indexOf(elem) === index && elem != undefined);

      //   const language = this.languages.find(l => l.code_deepl === this.task.language_code_deepl);
      //   const wakachi = language.space_wakachi ? ' ' : '';
      //   // console.log(language, "'" + wakachi + "'");

      //   let sentences = [];
      //   speakers.forEach((sp) => {
      //     let sentence_text = '';
      //     let cnt = 0;
      //     flagments.forEach((fa, idx) => {
      //       if(fa.flagments == undefined){return;}
      //       sentences[idx] = sentences[idx] && sentences[idx].length>0 ? sentences[idx] : [];
      //       if(String(fa.speaker) == sp){
      //         fa.flagments.forEach((f) => {
      //           if(f.match(re)){
      //             sentences[idx].push({
      //               uuid:           fa.uuid,
      //               cnt:            cnt,
      //               speaker:        sp,
      //               text:           sentence_text+f, 
      //               language_code:  this.task.code_deepl,
      //             });
      //             sentence_text = '';
      //             cnt = 0;
      //           }else{
      //             // const spaceWakachi = '';
      //             sentence_text += wakachi + f;
      //             cnt++;
      //           }
      //         }); 
      //       }
      //     });
      //   });
      //   console.log(sentences);

      //   return sentences;
      // },

      // removeTranslationTask: function (event) {
      //   // console.log(event.task_hashid);
      //   const task_hashid = event.task_hashid;
      //   return this.media_file.plan[0].tasks.map ( task => { return {...task} }).filter ( task => {
      //       if ( 'tasks' in task ) {
      //           task.children = this.removeTranslationTask (task_hashid );
      //       }
      //       return task.hashid !== task_hashid;
      //   });
      // },

      resetPlayer: function () {
        this.player.pause();
        if(this.playSegmentProcess_) clearInterval(this.playSegmentProcess_);
        if(this.playActiveSubtitle_) this.playActiveSubtitle_ = null;
      },

      addSubtitle: function (start, end, uuid = null, speaker, text = '', text_ex = '', trashed_at = null) {
        const newSubtitle = new Subtitle(start, end, uuid, speaker, text, text_ex, trashed_at);
        // console.log(newSubtitle);
        this.subtitles.push(newSubtitle);
        this.subtitles.sort((sa,sb) => Subtitle.compare(sa, sb));
        return newSubtitle;
      },

      addBlankSubtitleAfterCurrSubtitle: function (currSubtitle = null) {
        let start = 0.0
        let nextSubtitleIdx = 0;
        if(currSubtitle){
          let currSubtitleIdx = this.activeSubtitles.findIndex((s) => {
            return s === currSubtitle;
          });
          nextSubtitleIdx = currSubtitleIdx + 1;
          start = BigNumber(currSubtitle.end).plus(0.01).toString();
        }
        let end = this.duration;
        let nextSubtitle = this.activeSubtitles[nextSubtitleIdx];
        if(nextSubtitle){
          end = BigNumber(nextSubtitle.start).plus(-0.01).toString();
        }

        let duration = BigNumber(end).plus(-start);
        if(duration.lte(0.01)){alert('十分な余白がありません'); return;} 
        // console.log(duration.toString(), end);
        const newSubtitle = this.addSubtitle(start, end, null, 0);
        this.segmentFocused_ = newSubtitle;
        return newSubtitle;
      },

      playSegment: function(start, end, loop = 1) {
        this.resetPlayer();
        const main = () => {
          return new Promise((resolve, reject) => {
            this.seek(start);
            this.player.play();
            // console.log(this.player.time());
            this.playSegmentProcess_ = setInterval(() => {
              if(this.player.currentTime >=  end && this.player.playing){
                this.player.pause();
                this.seek(end);
                clearInterval(this.playSegmentProcess_);
                resolve();
              }
            }, 8);//ms
          });
        };

        let run = async () => {
          for(let i=0; i<loop; i++){
            await main();
            // console.log(i);
          }
        };
        run();
      },
      
      seek (sec = 0.0) {
        if(this.playSegmentProcess_) clearInterval(this.playSegmentProcess_);
        this.player.currentTime = sec;
      },

      setSubtitleCues(force = false) {
        if(!force && !this.subtitlesNotSetCCYet_) return;
        this.settingCCNow_ = true;
        if(this.textTrack){
          // this.textTrack.cues.clear();
          // console.log(this.textTracks);
          this.textTrack.mode = "disabled"
          // if(this.textTrack.cues.length > 0){
          //   // let cnt = 0;
          //   this.textTrack.cues.forEach(cue => {
          //     this.textTrack.removeCue(cue);
          //     console.log('cue.id: ' + cue.id);
          //     // cnt++;
          //   });
          //   // console.log(cnt);
          // }
          // this.textTrack.mode = "disabled"; // @todo: 雑い?
        }

        this.textTrack = this.player.addTextTrack("subtitles");//, "ja subtitles", 'ja');
        // this.player.textTrack.mode = "showing";
        // console.log('sads.length: '+this.task.task.media_file.sads.length);

        let cnt = 0;


        const re_escape = /\\/g; // better? with this.settings.fullStop
        // console.log(re_escape);
        this.activeSubtitles.forEach(subtitle => {
          // console.log(subtitle);
          if(subtitle.text.length > 0){
            const text = subtitle.text.replace(re_escape, '');
            let cue = new VTTCue(subtitle.start, subtitle.end, text);
            // cue.align = 'left';  //available
            // cue.vertical = 'lr'; //available
            cue.id = 'sub' + cnt;
            this.textTrack.addCue(cue);
          }
          cnt++;
        });
        this.textTrack.mode = 'showing';
        this.settingCCNow_ = false;
        this.subtitlesNotSetCCYet_ = false;
      },

      splitSegment: function (subtitle, caretPos) {
        
        const text1 = subtitle.text.substr(0, caretPos).trim();
        const text2 = subtitle.text.substr(caretPos).trim();
        console.log(text1, text2);
        let text1Rate = 0.50
        if(text1.length > 0 && text2.length > 0){
          text1Rate =  text1.length / (text1.length + text2.length) ;
        }
        const text1Duration = Number.parseFloat((subtitle.end -subtitle.start) * text1Rate).toFixed(2);
        const text1End = BigNumber(subtitle.start).plus(text1Duration).toFixed(2);
        const text2Start = BigNumber(text1End).plus(0.01).toFixed(2);

        const text_ex_length = subtitle.text_ex.length;
        const text_ex1_length = Math.floor(text_ex_length * text1Rate);
        const text_ex1 = subtitle.text_ex.substr(0, text_ex1_length).trim();
        const text_ex2 = subtitle.text_ex.substr(text_ex1_length).trim();

        subtitle.trash();
// console.log(subtitle.start, text1End.toString(),  null, subtitle.speaker, text1, text_ex1);
// console.log(text2Start.toString(), subtitle.end,  null, subtitle.speaker, text2, text_ex2);
        this.addSubtitle(subtitle.start, text1End.toString(),  null, subtitle.speaker, text1, text_ex1);
        this.addSubtitle(text2Start.toString(), subtitle.end,  null, subtitle.speaker, text2, text_ex2);

        let index_found = this.subtitles.findIndex((s) => s === subtitle);
        // console.log(index_found);
        this.subtitles.splice(index_found, 1);
        

      },

      joinSegments: function (currSubtitle, caretPos) {
        if(caretPos > 0) return;
        const currSubtitleIdx = this.activeSubtitles.findIndex((s) => {
          return s === currSubtitle
        });
        // console.log(currSubtitleIdx);
        
        const language = this.languages.find(l => l.code_deepl === this.task.language_code_deepl);
        const wakachi = language.space_wakachi ? ' ' : '';
        // console.log(language, "'" + wakachi + "'");

        for(let idx = currSubtitleIdx-1; idx >= 0; idx--){
          let prevSubtitle = this.activeSubtitles[idx];
          if(prevSubtitle.speaker === currSubtitle.speaker){
            const newText = prevSubtitle.text + wakachi + currSubtitle.text;
            let new_prev_text_ex = prevSubtitle.text_ex;
            let new_curr_text_ex = currSubtitle.text_ex;
            if(!!prevSubtitle.text_ex || !!currSubtitle.text_ex){
              new_prev_text_ex = prevSubtitle.text_ex ? prevSubtitle.text_ex : prevSubtitle.text;
              new_curr_text_ex = currSubtitle.text_ex ? currSubtitle.text_ex : currSubtitle.text;
            }
            let newTextEx = new_prev_text_ex + new_curr_text_ex;
            this.subtitles.splice(this.subtitles.findIndex((s) => {
              return s === currSubtitle;
            }), 1);
            this.subtitles.splice(this.subtitles.findIndex((s) => {
              return s === prevSubtitle;
            }), 1);
            const joinedSegments = this.addSubtitle(prevSubtitle.start, currSubtitle.end, null, currSubtitle.speaker, newText, newTextEx);
            this.segmentFocused_ = joinedSegments;
            break;
          }

        }

        


      },

      save(){
        // window.alert('saving');
        if(this.subtitlesNotSavedYet_) this._saveSubtitles();
      },

      _saveSubtitles() {
        this.savingNow_ = true;
        this.axios.post(
          '/tasks/'+this.task.hashid+'/subtitles', 
          this.subtitles
        ).then((response) => {
          this.setSubtitleCues(false);
          this.subtitlesNotSavedYet_ = false;
          setTimeout(()=>{
            this.savingNow_ = false;
          }, 500);
        });
      },
      
      saveSettings() {
        // if (isLocalStorage() /* function to detect if localstorage is supported*/) {
        // localStorage.setItem('settings', JSON.stringify(this.settings));
        this.axios.post(
          '/tasks/'+this.task.hashid+'/settings', 
          this.settings
        ).then((response) => {
        });
        // }
      },

      confirmSave (event) {
        if (this.subtitlesNotSavedYet_){
          event.returnValue = "編集中のものは保存されませんが、よろしいですか？";
        }
      },
      
      // isOnline() {
      //   console.log(`online`)
      // },
      // isOffline() {
      //   console.log(`offline`)
      // },

    },

    computed: {

      // task: function(){
        
      // },



      activeSubtitles: function (active = true) {
        // return this.subtitles.filter((s) => !(s.trashed_at));
        return this.subtitles.filter((s) => !(s.trashed_at));
      },
      inactiveSubtitles: function () {
        const subtitles = this.subtitles.filter((s) => !!s.trashed_at);
        // const subtitles = this.subtitles.filter((s) => !!s.trashed_at);
        return subtitles.sort((sa, sb) => sa.trashed_at - sb.trashed_at);
      },
      // languagesUniqByCodeDeepl: function(){
      //   let languages = this.languages;
      //   languages =  _.uniqBy(languages, 'code_deepl');
      //   // languages = languages.map(lang => )
      //   languages.map(function(l){
      //     l.code_bcp_47_without_region = l.code_bcp_47.replace(/-.{2}$/, '');
      //     return l;
      //   });
      //   return languages;
      // },

    },

    mounted () {

      this.axios.get(
        '/languages'
      ).then((response) => {
        this.languages = response.data;
        
        this.axios.get(
          '/media_files/'+this.$route.params.media_file_hashid
        ).then((response) => {
          // console.log(response.data);
          this.media_file = response.data;
          // this.task_hashid = response.data.tasks.find(task => task.demand === 'transcription').hashid;
          // console.log(this.task);
          this.player = document.getElementById("video-player");
          // console.log(this.player);
          this.player.load();
          this.player.src = this.media_file.url;

          this.player.oncanplay = (event) => {
            this.duration = this.player.duration;
          };
          this.player.onloadedmetadata = (event) => {
            this.duration = this.player.duration;
          };
          this.player.onloadeddata = (event) => {
            this.duration = this.player.duration;
          };

          // this.changeTask(this.media_file.plan[0].hashid);
          setInterval(() => {
            // this.seek(0.1); this.seek(0.0);
            this.currentTime = this.player.currentTime;
          }, 9);//ms

        }).catch(error => console.debug(error));

        this.axios.get(
          '/tasks/' + this.$route.params.task_hashid
        ).then((response) => {

          // this.tasks[hashid] = response.data; // adding
          // this.task = this.tasks[hashid]; // adding
          this.task = response.data;


          this.axios.get(
            '/tasks/'+this.$route.params.task_hashid+'/subtitles'
          ).then((response) => {
            if(response.data.length <= 0){
              if(this.media_file.seg_res?.length){
                let subtitles = this.media_file.seg_res.map(s => new Subtitle(s.start, s.end, s.uuid, s.speaker));

                // this.tasks[hashid].subtitles = subtitles; // adding
                this.subtitles = subtitles; // adding
                // this.subtitles = subtitles;// removing
              }
            }else{
              const subtitles = response.data.map(s => new Subtitle(s.start, s.end, s.uuid, s.speaker,  s.text, s.text_ex, s.trashed_at));

              // this.task.subtitles = subtitles; // adding
              // this.subtitles = this.task.subtitles; // adding
              this.subtitles = subtitles;// removing
              this.setSubtitleCues(true);
            }
            // this.sentences = this.subtitles2sentences(this.subtitle);
            // this.subtitles.sort((s1, s2) => {// removing
            //   return BigNumber(s1.start).lt(BigNumber(s2.start)) ? -1 : +1;
            // });
            this.subtitles.sort((s1, s2) => {
              return BigNumber(s1.start).lt(BigNumber(s2.start)) ? -1 : +1;
            });

            // this.loadingTask = false;

          });

          // const hashid = this.task.hashid;


          // let _getPreviousTask = (task, hashid) => {
          //     for(let _task of task.tasks){
          //         if (_task.hashid === hashid) return task;
          //         if(_.isObject(task)){
          //             let result = _getPreviousTask(_task, hashid);
          //             if (!!result) {
          //                 return result;
          //             }
          //         }
          //     }
          //     // console.log('getPreviousTask')
          //     return undefined;
          // };

          const plan = this.media_file.plan_ex;
          

          
          this.axios.get(
            '/tasks/'+this.task.hashid+'/settings'
          ).then((response) => {
            if(Object.keys(response.data).length > 0){
              const settings = response.data;
              

              this.settings = settings;
              
          }});

          // return response.data;
        });


      });
      
      

    },

    watch: {

      'subtitles': {// changed
        handler: _.debounce(function (newSubtitles, oldSubtitles) {
          if(oldSubtitles.length > 0){
            this.subtitlesNotSavedYet_ = true;
            this.subtitlesNotSetCCYet_ = true;
          }

          this.sentences = this.subtitles2sentences(this.activeSubtitles);
          // console.log(this.sentences);

          this.setSubtitleCues();
        }, 600),
        deep: true,
        immediate: false,
      },

      'playSegmentProcess_': {//for testing
        handler: _.debounce(function (newPlaySegmentProcess_, oldPlaySegmentProcess_) {
          // console.log(newPlaySegmentProcess_, oldPlaySegmentProcess_);
        }, 20),
        deep: false,
        immediate: false,
      },

      'segmentFocused_': {
        handler: _.debounce(function (segmentFocused_, segmentFocused_Before) {
          if(!segmentFocused_) return;
          // console.log(segmentFocused_);
          const idx = this.activeSubtitles.findIndex(s => s === segmentFocused_);
          // console.log(idx, document.querySelectorAll('#xsubtitles .subtitle'));
          if(!isNaN(idx) && idx >= 0){
            const el = document.querySelectorAll('#xsubtitles .subtitle .segment-header')[idx];
            // console.log(el);
            if (!!el) {
              el.scrollIntoView({block: 'end', behavior: 'smooth', inline: "center"});
              // el.querySelector('.text').focus();
              this.seek(this.activeSubtitles[idx].start);

            }
          }

          segmentFocused_ = null;

        }, 300),
        deep: false,
        immediate: false,
      },
      
      'segmentFocused2_': {
        handler: _.debounce(function (segmentFocused2_, segmentFocused2_Before) {
          if(!segmentFocused2_) return;
          // console.log(segmentFocused_);
          const idy = this.activeSubtitles.findIndex(s => s === segmentFocused2_);
          // console.log(idx, document.querySelectorAll('#xsubtitles .subtitle'));
          if(!isNaN(idy) && idy >= 0){
            const el = document.querySelectorAll('#ysubtitles .subtitle')[idy];
            // console.log(el);
            if (el) {
              // el.parentElement.scrollLeft = el.offsetLeft - 20;
              el.scrollIntoView({block: 'center', behavior: 'smooth', inline: "center"});
              // el.querySelector('.text').focus();
              this.seek(this.activeSubtitles[idy].start);

            }
          }

          segmentFocused2_ = null;

        }, 300),
        deep: false,
        immediate: false,
      },

      'task': {
        handler: _.debounce(function(new_task, old_task) {
          this.axios.put(
            // '/media_files/' + this.media_file.hashid + 
            '/tasks/' + this.task.hashid,
            {
              'language_code_deepl': new_task.language_code_deepl,
              // 'plan': new_media_file.plan_ex,
              
            }
          ).then((response) => {
            
          });
        }, 500),
        deep: true,
        immediate: false,
      },

      'settings': {
        handler: _.debounce(function(newSubtitles, oldSubtitles) {
          this.saveSettings();
        }, 800),
        deep: true,
        immediate: false,
      },
    },
    filters: {
    },
    created () {
      window.addEventListener("beforeunload", this.confirmSave);
    },
    destroyed () {
      window.removeEventListener("beforeunload", this.confirmSave);
    },
  }

</script>

<style>
  
  /* @import url("nodevideo.js/dist/video-js.css"); */

  #upper-container {
    /* margin-top: 4px; */
    width : 100%;
    display: flex;
  }

  #video-player.lg {
    width: 640px;
    height: 360px;
  }
  #video-player.md {
    width: 544px;
    height: 306px;
  }
  #video-player.sm {
    width: 480px;
    height: 270px;
  }
  /* #video-player.xs {
    width: 480px;
    height: 270px;
  } */

  #ysubtitles {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-flow: column;
  }

  #ysubtitles.lg {
    height: 366px;
  }
  #ysubtitles.md {
    height: 306px;
  }
  #ysubtitles.sm {
    height: 288px;
  }
  #ysubtitles.xs {
    height: 270px;
  }
  .animeS {
    transition-duration: .1s;
    transition-property: transform;
  }
  .animeS:hover {
    transform: scale(1.03);
    /* font-weight: bold; */
  }
  .animeM {
    transition-duration: .1s;
    transition-property: transform;
  }
  .animeM:hover {
    transform: scale(1.1);
    /* font-weight: bold; */
  }

  .animeDock {
    transition-duration: .1s;
    transition-property: transform;
  }
  .animeDock:hover {
    transform: scale(1.2);
    /* font-weight: bold; */
  }
  .trashed {
    transition-duration: .1s;
    transition-property: transform;
  }
  .trashed:hover {
    transform: scale(1.03);
    /* font-weight: bold; */
  }
  #ysubtitles .item {
    margin-left: 5px;
    margin-bottom: 5px;
    /* margin-right: 5px; */
    display: flex;
    flex-flow: row;
  }
  /* #ysubtitles .item.playing {
    color: red;
  } */
  #ysubtitles .item .hashnum {
    width: 75px;
  }
  #ysubtitles .item .start {
    width: 120px;
  }
  #ysubtitles .item .text {
    margin-left: 24px;
    /* margin-right: 10px; */
    width: 100%;
  }
  
  #ysubtitles .item .end {
    width: 120px;
  }

  #ysubtitles .item .hyphen {
    width: 35px;
  }


  .xsubtitles-lane {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    background: linear-gradient(to bottom, #eee, white);
  }
  .xsubtitles-lane .add-segment {
    padding-right: 4px;
    transition-duration: .3s;
    transition-property: transform;
  }
  .xsubtitles-lane .add-segment:hover {
    transform: scale(1.3);
    /* font-weight: bold; */
  }

  #xsubtitles {
    /* width: 100%; */
    /* height: 280px; */
    padding-top: 0px;
    padding-bottom: 10px;
      /* overflow-x: scroll; */
    overflow-x: auto;
    display: flex;
    flex-flow: row;
    margin-top: 15px;
    position: relative;

  }
  #xsubtitles .add-segment {
    padding-left: 4px;
    padding-right: 4px;
    transition-duration: .3s;
    transition-property: transform;
/* 
  @include hideTapHighlightColor();
  @include hardwareAccel();
  @include improveAntiAlias(); */

  }
  #xsubtitles .add-segment:hover {
    transform: scale(1.3);
    /* font-weight: bold; */
  }
  #xsubtitles .subtitle {
    padding: 8px;
    /* height: 100%; */
    /* display: inline-block; */
    width: calc(1080px / 2.76);
    /* width: 40px; */
    flex: 0 0 auto;
    /* display: flex; */
    flex-flow: column;
    /* height: 5rem; */
    /* overflow-y: hidden; */
    background-color: #fff;
  }
  #xsubtitles .subtitle .text{
    height: 100%;
  }
  #xsubtitles .subtitle .text_ex{
    height: 100%;
  }
  /* #xsubtitles .subtitle::after {
    content: '<div>hello</div>';
    font-size: 2em;
    display: block;
  } */

  #ai {
    width: 100%;
      /* overflow-x: scroll; */
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    margin-top: 3px;

  }
  #ai .item {
    padding: 10px;
    margin-right: 16px;
    /* display: inline-block; */
    /* width: calc((100% - 20px) / 5); */
    width: calc(1080px / 2.9);
    /* width: 40px; */
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    /* flex-flow: row; */
    /* height: 5rem; */
    /* overflow-y: hidden; */
    background-color: #eee;
    border-radius: 5px;
  }
  #ai .control {
    display: flex;
    flex-flow: row;
  }

  #seekbar-scale {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  #seekbar-controls {
    width: 100%;
    display: flex;
    /* justify-content: space-between; */
    justify-content: center;
    align-items: flex-end;
  }
  #current-time {
    /* font-size: 1.39rem; */
    width: 210px;
  }




  /* https://qiita.com/nezurika/items/ea3ae9a3e9ec3c158528 */
.input-range[type="range"] {
   -webkit-appearance: none;
   appearance: none;
   background-color: #c7c7c7;
   height: 3px;
   width: 100%;
}
 .input-range[type="range"]:focus, .input-range[type="range"]:active {
   outline: none;
}
 .input-range[type="range"]::-webkit-slider-thumb {
   -webkit-appearance: none;
   appearance: none;
   cursor: pointer;
   position: relative;
   border: none;
   width: 5px;
   height: 15px;
   display: block;
   /* box-shadow: gray; */
   /* background-color: #262626; */
   /* background-color: orangered; */
   /* background-color: #444; */
   background-color: #333;
   /* border-radius: 5%;
   -webkit-border-radius: 5%; */
}
 
#video-player::cue {
  /* background-image: #000; */
  /* background-image: rgba(0, 0, 0, 0.6); */
  /* background-color: rgba(0, 0, 0, 0.6); */
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  color: #fff;
}

/* 表示・非表示アニメーション中 500ms */
.v-enter-active, .v-leave-active {
  transition: all 300ms;
}

/* 表示アニメーション開始時 ・ 非表示アニメーション後 */
.v-enter, .v-leave-to {
  opacity: 0;
}
/* 要素が移動する時に700msで移動するように指定 */
.v-move {
  transition: all 300ms;
}

.v-leave-active {
  /* 移動のトランジションをさせる場合は非表示アニメーション中に position: absoluteを指定しないと正しく動作しない */
  position: absolute;
}
/* .fade-enter, .fade-leave-to {
  opacity: 0
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 1.0s
} */

.speaker-0{
  color: #FFC312;
}
.speaker-1{
  color: #F79F1F;
}
.speaker-2{
  color: #EE5A24;
}
.speaker-3{
  color: #EA2027;
}
.speaker-4{
  color: #C4E538;
}
.speaker-5{
  color: #A3CB38;
}
.speaker-6{
  color: #009432;
}
.speaker-7{
  color: #006266;
}
.speaker-8{
  color: #12CBC4;
}
.speaker-9{
  color: #A3CB38;
}
.speaker-10{
  color: #1289A7;
}
.speaker-11{
  color: #1B1464;
}
.speaker-12{
  color: #FDA7DF;
}
.speaker-13{
  color: #D980FA;
}
.speaker-14{
  color: #9980FA;
}
.speaker-15{
  color: #5758BB;
}




.subheading {
  border-left: solid 2px silver;/*左線*/
  padding-inline: 4px;
  margin-bottom: 2px;
}
::placeholder{
  color: #ccc;
  /* font-style: italic; */
}


</style>