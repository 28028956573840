import { render, staticRenderFns } from "./Sentence.vue?vue&type=template&id=6fd6e548&scoped=true&"
import script from "./Sentence.vue?vue&type=script&lang=js&"
export * from "./Sentence.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fd6e548",
  null
  
)

export default component.exports