// https://stackoverflow.com/a/31133401
Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
  get: function(){
      return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
  }
})

import Vue from 'vue'

// import Vuex  from 'vuex'
// import {store}  from './vuex/store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import axios from 'axios' //追記
import VueAxios from 'vue-axios' //追記


// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueI18n from 'vue-i18n'

import router from './router'



axios.defaults.baseURL = process.env.VUE_APP_BACKEND_API_BASE_URL;
axios.defaults.withCredentials = true;
axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    config.headers = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      // 'X-Shingo-With': 'MAEDA',
      'X-Blur': 'Plastic',
      'Authorization': `Bearer ${window.localStorage.getItem('access_token')}`
    };
    // config.responseType = 'json';
    config.withCredentials = true;
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => response,
  error => {
    console.log(error);
    // You can extract message from your API, if you want
    const message = error.message || error.code
    const vm = new Vue();
    if(error.code != 'ERR_BAD_REQUEST'){
      vm.$bvToast.toast(`Server call returned error: ${message} \n${error.response.data?.exception}`, {
        title: `${error.message}`,
        variant: 'danger',
      });
    }
    return Promise.reject(error)
  },
);


 
// Vue.use(VueReadMoreSmooth);
Vue.use(VueAxios, axios)

// console.log(BootstrapVue)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueI18n)

Vue.use(require('vue-shortkey'))
// Vue.use(VueVirtualScroller)
Vue.config.productionTip = false
// Vue.config.silent = true
// Vue.config.productionTip = false
// console.log(Vue.version);


const messages = {
  en: {
    localeName: 'English',
    'DIY Translation Subs': 'DIY Translation Subs with AI',
    message: {
      welcome: 'Welcome',
      please_select: 'Select Task on the Plan tab.',
      add_translation_task: 'add translation subs',
    },
    VIDEO: 'VIDEO',
    Sub: 'Sub',
    Pay_now: 'Pay now',
    translation: 'Translation',
    translate: 'Translate',
    transcription: 'Transcription',
    transcribe: 'Transcribe',
    copy: 'Copy',
    source_text: 'Source Text',
    Revised_Transcription_for_Translation: 'Revised Transcription',
    Translation_Test: 'Translation Test',
    Batch_Translation: 'Batch Translation',
    add_to_cart: 'Add to cart',
    remove_from_cart: 'Remove from cart',
    Title: 'Title',
    Channel: 'Channel',
    Description: 'Description',
    Duration: 'Duration',
    Filled: 'Filled',
    Total: 'Total',
    Progress: 'Progress',
    Edit: 'Edit',
    updated_at: 'Last update',
    Download_Subs: 'Download sub file',
    make_a_project: 'make a project',
    No_Projects: 'There are no projects',
    Home: 'Home',
    Projects: 'Projects',
    characters: 'c',
    seconds: 's',
    VoiceActiveDuration: 'Voice Active Duration',
    'Movie URL here': 'Video URL here (YouTube/YouTube Shorts)',
    lang: {
      "BG": "Bulgarian",
      "CS": "Czech",
      "DA": "Danish",
      "DE": "German",
      "EL": "Greek",
      "EN": "English",
      "ES": "Spanish",
      "ET": "Estonian",
      "FI": "Finnish",
      "FR": "French",
      "HU": "Hungarian",
      "ID": "Indonesian",
      "IT": "Italian",
      "JA": "Japanese",
      "KO": "Korean",
      "LT": "Lithuanian",
      "LV": "Latvian",
      "NB": "Norwegian",
      "NL": "Dutch",
      "PL": "Polish",
      "PT": "Portuguese",
      "RO": "Romanian",
      "RU": "Russian",
      "SK": "Slovak",
      "SL": "Slovenian",
      "SV": "Swedish",
      "TR": "Turkish",
      "UK": "Ukrainian",
      "ZH": "Chinese",
    },
  },
  ja: {
    localeName: '日本語',
    'DIY Translation Subs': '翻訳字幕DIY with AI',
    message: {
      welcome: 'ようこそ',
      please_select: '"Plan"タブで"Task"を選択してください。',
      add_translation_task: '翻訳字幕 を 追加',
    },
    VIDEO: '動画',
    Sub: '字幕',
    Pay_now: 'お支払いに進む',
    translation: '翻訳',
    translate: 'AI翻訳',
    transcription: '文字起こし',
    transcribe: 'AI文字起こし',
    copy: 'コピー',
    source_text: 'ソース',
    Revised_Transcription_for_Translation: '翻訳のための補正',
    Translation_Test: '翻訳テスト',
    Batch_Translation: '一括翻訳',
    add_to_cart: 'カートに加える',
    remove_from_cart: 'カートから取り除く',
    Title: 'タイトル',
    Channel: 'チャンネル',
    Description: '概要',
    Duration: '時間',
    Filled: '入力済み',
    Total: '全て',
    Progress: '進捗',
    Edit: '編集',
    updated_at: '更新',
    Download_Subs: '字幕ファイルのDL',
    make_a_project: 'プロジェクトを作る',
    No_Projects: 'ログインしていないか、プロジェクトがありません。',
    Home: 'Home',
    Projects: 'Yourプロジェクト',
    VoiceActiveDuration: '発話区間',
    characters: '字',
    seconds: '秒',
    'Movie URL here': 'ここに動画のURLをコピペしてください (YouTube/YouTube ショート)',
    lang: {
      "BG": "ブルガリア語",
      "CS": "チェコ語",
      "DA": "デンマーク語",
      "DE": "ドイツ語",
      "EL": "ギリシャ語",
      "EN": "英語",
      "ES": "スペイン語",
      "ET": "エストニア語",
      "FI": "フィンランド語",
      "FR": "フランス語",
      "HU": "ハンガリー語",
      "ID": "インドネシア語",
      "IT": "イタリア語",
      "JA": "日本語",
      "KO": "韓国語",
      "LT": "リトアニア語",
      "LV": "ラトビア語",
      "NB": "ノルウェー語",
      "NL": "オランダ語",
      "PL": "ポーランド語",
      "PT": "ポルトガル語",
      "RO": "ルーマニア語",
      "RU": "ロシア語",
      "SK": "スロバキア語",
      "SL": "スロベニア語",
      "SV": "スウェーデン語",
      "TR": "トルコ語",
      "UK": "ウクライナ語",
      "ZH": "中国語",
    },
  },
  zh: {
    localeName: '中文',
    'DIY Translation Subs': '翻译字幕DIY with AI',
    message: {
      welcome: '欢迎',
      please_select: '在“Plan”选项卡中选择“Task”。',
      add_translation_task: '添加翻译字幕',
    },
    VIDEO: '视频',
    Sub: '字幕',
    Pay_now: '立即付款',
    translation: '翻译',
    translate: 'AI翻译',
    transcription: '转录',
    transcribe: 'AI转录',
    copy: '复制',
    source_text: '原文',
    Revised_Transcription_for_Translation: '为翻译修改转录',
    Translation_Test: '翻译测试',
    Batch_Translation: '批量翻译',
    add_to_cart: 'Checkout -> Download',
    remove_from_cart: 'Remove from cart',
    Title: 'Title',
    Channel: 'Channel',
    Description: 'Description',
    Duration: 'Duration',
    Filled: '进入',
    Total: '总数',
    Progress: '进步',
    Edit: '编辑',
    updated_at: '更新',
    Download_Subs: '下载字幕文件',
    make_a_project: '做一个项目',
    No_Projects: '没有项目。',
    Home: 'Home',
    Projects: '项目',
    VoiceActiveDuration: '语音活动时间',
    characters: '字',
    seconds: '秒',
    'Movie URL here': '在此处粘贴视频URL(YouTube/YouTube Shorts)',
    lang: {
      "BG": "保加利亚语",
      "CS": "捷克语",
      "DA": "丹麦语",
      "DE": "德语",
      "EL": "希腊语",
      "EN": "英语",
      "ES": "西班牙语",
      "ET": "爱沙尼亚语",
      "FI": "芬兰语",
      "FR": "法语",
      "HU": "匈牙利语",
      "ID": "印度尼西亚语",
      "IT": "意大利语",
      "JA": "日语",
      "KO": "韩语",
      "LT": "立陶宛语",
      "LV": "拉脱维亚语",
      "NB": "挪威语",
      "NL": "荷兰语",
      "PL": "波兰语",
      "PT": "葡萄牙语",
      "RO": "罗马尼亚语",
      "RU": "俄语",
      "SK": "斯洛伐克语",
      "SL": "斯洛文尼亚语",
      "SV": "瑞典语",
      "TR": "土耳其语",
      "UK": "乌克兰语",
      "ZH": "中文",
    },
  },
  ko: {
    localeName: '한국인',
    'DIY Translation Subs': '번역 자막 DIY with AI',
    message: {
      welcome: '환영',
      please_select: '"Plan"탭에서 "Task"를 선택하십시오.',
      add_translation_task: '번역 서브 추가',
    },
    VIDEO: '동영상',
    Sub: '자막',
    Pay_now: '지금 결제하기',
    translation: '번역',
    translate: 'AI 번역',
    transcription: '문자 발생',
    transcribe: 'AI 문자 발생',
    copy: '복사 및 붙여넣기',
    source_text: '원문',
    Revised_Transcription_for_Translation: '번역을 위한 필사본 수정',
    Translation_Test: '번역 테스트',
    Batch_Translation: '일괄 번역',
    add_to_cart: 'Checkout -> Download',
    remove_from_cart: 'Remove from cart',
    Title: 'Title',
    Channel: 'Channel',
    Description: 'Description',
    Duration: 'Duration',
    Filled: '입력됨',
    Total: '총수',
    Progress: '진척',
    Edit: '편집하다',
    updated_at: '업데이트',
    Download_Subs: '자막 파일 다운로드',
    make_a_project: '프로젝트 만들기',
    No_Projects: '프로젝트가 없습니다.',
    Home: 'Home',
    Projects: '프로젝트',
    VoiceActiveDuration: '음성 활성 시간',
    characters: 'c',
    seconds: 's',
    'Movie URL here': '여기에 동영상 URL 붙여넣기 (YouTube/YouTube Shorts)',
    lang: {
      "BG": "불가리아어",
      "CS": "체코어",
      "DA": "덴마크어",
      "DE": "독일어",
      "EL": "그리스어",
      "EN": "영어",
      "ES": "스페인어",
      "ET": "에스토니아어",
      "FI": "핀란드어",
      "FR": "프랑스어",
      "HU": "헝가리어",
      "ID": "인도네시아어",
      "IT": "이탈리아어",
      "JA": "일본어",
      "KO": "한국어",
      "LT": "리투아니아어",
      "LV": "라트비아어",
      "NB": "노르웨이어",
      "NL": "네덜란드어",
      "PL": "폴란드어",
      "PT": "포르투갈어",
      "RO": "루마니아어",
      "RU": "러시아어",
      "SK": "슬로바키아어",
      "SL": "슬로베니아어",
      "SV": "스웨덴어",
      "TR": "터키어",
      "UK": "우크라이나어",
      "ZH": "중국어",      
    },
  }
};



// "BG": "불가리아어",
// "CS": "체코어",
// "DA": "덴마크어",
// "DE": "독일어",
// "EL": "그리스어",
// "EN": "영어",
// "ES": "스페인어",
// "ET": "에스토니아어",
// "FI": "핀란드어",
// "FR": "프랑스어",
// "HU": "헝가리어",
// "ID": "인도네시아어",
// "IT": "이탈리아어",
// "JA": "일본어",
// "KO": "한국어",
// "LT": "리투아니아어",
// "LV": "라트비아어",
// "NB": "노르웨이어",
// "NL": "네덜란드어",
// "PL": "폴란드어",
// "PT": "포르투갈어",
// "RO": "루마니아어",
// "RU": "러시아어",
// "SK": "슬로바키아어",
// "SL": "슬로베니아어",
// "SV": "스웨덴어",
// "TR": "터키어",
// "영국": "우크라이나어",
// "ZH": "중국어",      





const i18n = new VueI18n({
  legacy: false,
  locale: window.navigator.language.slice(0, 2),
  fallbackLocale: 'en',
  globalInjection: true,
  messages: messages,
});


Vue.directive('init', {
  bind: function (el, binding, vnode) {
    vnode.context[binding.arg] = binding.value
  }
})

import { dateFilter } from "vue-date-fns";

Vue.filter("date", dateFilter);

Vue.filter('mcsscff', function(sec = '0', floatPrecision = 2) {
    // const floatPrecision = 2;
    sec = String(sec)
    if (!sec || isNaN(sec)) return ''
    if(!sec.match(/\./)){
      sec = sec + '.0';
    }
    let m = Math.floor(parseFloat(sec) / 60);
    let sf = parseFloat(sec) - 60 * m;
    let s = String(sf).split(".")[0];
    let ss = String(s).padStart(floatPrecision, '0');
    let ff = String(sec).split(".")[1];
    if(String(ff).length < floatPrecision){
      ff = ff + '0'.repeat(floatPrecision - String(ff).length);
    }
    if(String(ff).length > floatPrecision){
      ff = ff.slice(0, -(String(ff).length - floatPrecision));
    }
    let mssff = m + ':' + ss + '.' + ff;

    return mssff;
  }
);

Vue.filter('scff', function(sec = '0', floatPrecision = 2) {
    sec = parseFloat(sec);
    let scf = Math.round(sec * (10**floatPrecision)) / 10**floatPrecision
    let s = String(scf).split(".")[0];
    let ff = String(scf).split(".")[1];
    if(!ff){ ff = '0'}
    if(String(ff).length < floatPrecision){
      ff = ff + '0'.repeat(floatPrecision - String(ff).length);
    }
    if(String(ff).length > floatPrecision){
      ff = ff.slice(0, -(String(ff).length - floatPrecision));
    }
    let scff = s + '.' + ff;
    return scff;
  }
);

Vue.filter('capitalize', function(value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
});



import App from './App.vue'

new Vue({
  el: '#app',
  render: h => h(App),
  router,
  i18n,
});
