<template>
  <div class="row">
    <div class="d-flex justify-content-center background">
      <div class="col-8 mt-5 pt-3">
        <div class="text-center sample"><h1><span style="background-color: #ddd;"> YouTube 동영상이 국경을 넘어갑니다. </span></h1></div>
        <div class="text-center mt-5" style="background-color: #fff;"><h3>최신 AI 탑재. 번역 자막을 쉽게 DIY! </h3></div>
        <div class="mt-5">
          <b>AI의 강력한 지원으로 번역 자막을 편하게 </b>
          <ul>
            <li>pyannote.audio에 의한 고정밀 발화 구간 검출. </li>
            <li>OpenAI Whisper의 고정밀 문자 발생. </li>
            <li>DeepL SE DeepL의 각 언어로 자연스러운 번역. </li>
          </ul>
        </div>
        <div class="mt-4">
          <b> 합리적인 가격 </b>
          <ul>
            <li>DIY한 자막의, VTT 파일 다운로드시에 과금. </li>
            <li>요금은 발화 구간(=자막 표시 구간)에 대해 1언어 1분당 1.0 USD가 초 단위로 산출됩니다. </li>
          </ul>
        </div>
        <div class="text-center mt-5"><h4><span>자, 곧 시작하세요!</span></h4></div>
        <div class="text-center mt-4">

          <a class="text-dark clickable" v-if="auth.currentUser !== null">
            <b-button href="/media_files" variant="info">"프로젝트"로 이동</b-button>
          </a>
          <a class="text-dark clickable" @click="signIn()" v-else>
            <button type="button" class="login-with-google-btn" >
              Sign in with Google
            </button>
          </a>
        </div>
      </div>
    </div>
   
  </div>
</template>

<script>

import {auth, signInGoogle} from '../myfirebase.js';

export default {
  name: 'Home',

  data () {
    return {
      auth: auth,//20230818
    }
  },
  methods: {

    signIn(){
      signInGoogle().then((result)=>{
        // console.log('successfully sign in.');

        window.localStorage.setItem('access_token', result.user.accessToken);
        this.axios.put(
          '/auth', 
          { id_token: result.user.accessToken }
        ).then((response) => {

          window.location.href = '/media_files';
        });
      });
    },
    
  },

  watch: {
  }
}
</script>

<style>
/* .fuchidori {
  color: #000;
  -webkit-text-stroke: 3px #FFF;
} */
.sample {
  color: #222;
  /* text-shadow: 2px 3px 5px #aaa; */
  transform: rotate(2deg);
}
.background {
  width: 100%;
  height: 1280px;
  background-image: url("../assets/translation-7774314_1920.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-color:rgba(255,255,255,0.8);
  background-blend-mode:lighten;
}

.login-with-google-btn {
  transition: background-color .3s, box-shadow .3s;
    
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
  
  /* &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }
  
  &:active {
    background-color: #eeeeee;
  }
  
  &:focus {
    outline: none;
    box-shadow: 
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px #c8dafc;
  }
  
  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
  } */
}
</style>