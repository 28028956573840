import { render, staticRenderFns } from "./SubtitleIndex.vue?vue&type=template&id=483e0b62&scoped=true&"
import script from "./SubtitleIndex.vue?vue&type=script&lang=js&"
export * from "./SubtitleIndex.vue?vue&type=script&lang=js&"
import style0 from "./SubtitleIndex.vue?vue&type=style&index=0&id=483e0b62&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "483e0b62",
  null
  
)

export default component.exports