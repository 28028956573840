<template>
  <div class="row" id="media_files">
    <div class="col-12" v-if="!receipt">
      <!-- <pre>cart: {{task_hashidsInCart}}</pre> -->
    <!-- <div>{{$store.state.message}}</div> -->
    <!-- <pre>{{ this.media_files.length }}</pre> -->
      <template>
        <b-breadcrumb class="small">
          <b-breadcrumb-item active>{{ $t('Projects') }}</b-breadcrumb-item>
          <!-- <b-breadcrumb-item v-if="!!media_file.info" active>{{ media_file.info.title }}</b-breadcrumb-item> -->
        </b-breadcrumb>
      </template>
      <div>
        <b-overlay
          :show="sending" 
          spinner-variant="transparent"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <b-input-group prepend="" class="mt-3">
            <b-form-input v-model="src_url" :disabled="sending" class="clock" trim :placeholder="$t('Movie URL here')" @keydown.enter.native="sendUrl(src_url)" :state="yturlState" size="lg"></b-form-input>
            <b-input-group-append>
              <b-button variant="secondary" @click="sendUrl(src_url)" :disabled="!(yturlState) || sending">{{$t('make_a_project')}}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-overlay>
      </div>
      
      <div class="mt-4 text-center">
        <b-icon icon="arrow-down" scale="2" variant="secondary" aria-hidden="true"></b-icon>
      </div>

      
      <div class="mt-3">
        <div v-if="media_files.length > 0">
          <div>
            <b-list-group>
              <b-list-group-item v-for="media_file in media_files_displayed()" :key="media_file.id" class="flex-column align-items-start">
                <div v-if="media_file.status === 'getting_ready' || media_file.status === 'failed'">
                  <b-overlay
                    :show="media_file.status === 'getting_ready'"
                    spinner-variant="transparent"
                    spinner-type="spinner"
                    rounded="sm"
                    opacity="0.1"
                    :spinner-small="false"
                  >
                    <div class="text-center">
                      <b-icon icon="stopwatch" font-scale="2" animation="cylon"></b-icon>
                    </div>
                    <div class="d-flex w-100 justify-content-between">
                      <div><strong class="clock marquee">Preprocessing {{ media_file.src_url }} now. Please wait.</strong> <span v-if="media_file.status === 'failed'" class="text-danger small"><b-icon icon="bug-fill" aria-hidden="true"></b-icon>FAILED; {{ media_file.status_detail }}</span></div>
                      <div><span class="clickable" @click="deleteMediaFile(media_file.hashid)"><b-icon icon="trash" aria-hidden="true"></b-icon></span></div>
                    </div>
                  
                  </b-overlay>
                </div>
                <div v-else>
                    <div class="d-flex w-100 justify-content-between clickable" @click="() => $router.push({ name: 'tasks', params: { media_file_hashid: media_file.hashid} })">
                      <div class="mb-1">{{media_file.info.title}}</div>
                      <div><small class="clock text-muted">{{ new Date(media_file.created_at) | date('yyyy-MM-dd HH:mm')}}</small></div>
                    </div>
                    <div class="d-flex w-100 justify-content-between">
                      <div><small>{{media_file.info.channel}}</small></div>
                      <div><span class="clickable" @click="deleteMediaFile(media_file.hashid)"><b-icon icon="trash" aria-hidden="true"></b-icon></span></div>
                    </div>
                </div>
              </b-list-group-item>
            </b-list-group>
          </div>
          <div class="overflow-auto">
            <div class="mt-2">
              <b-pagination
                v-model="currentPage_mf"
                :total-rows="media_files.length"
                :per-page="perPage_mf"
                align="center"
                size="md"
              ></b-pagination>
            </div>
          </div>
        </div>

        <div class="text-muted text-center" v-else>
          {{ $t('No_Projects') }}
        </div>
      </div>


      <div class="mt-3" v-if="false">
        <div class="mb-2 mx-1">購入履歴(領収書)Receipts</div>
        <!-- <h5>購入履歴(領収書)Receipts</h5> -->
        <div v-if="false">
          <b-card title="Receipt" sub-title="">
            <!-- <b-card-text>
              Some quick example text to build on the <em>card title</em> and make up the bulk of the card's
              content.
            </b-card-text>

            <b-card-text>A second paragraph of text in the card.</b-card-text> -->

            <div class="row">
              <div class="col-md-12 small">
                <div class="panel panel-default">
                  <!-- <div class="panel-heading">
                    <h3 class="panel-title"><strong>Order summary</strong></h3>
                  </div> -->
                  <div class="panel-body">
                    <div class="table-responsive">
                      <table class="table table-sm table-condensed">
                        <thead>
                          <tr>
                            <td class="thick-line">Item</td>
                            <!-- <td class="text-center thick-line" width="8%"><strong>Price</strong></td> -->
                            <!-- <td class="text-center thick-line" width="10%"><strong>Quantity</strong></td> -->
                            <td class="text-right thick-line" width="8%">Unit Fee(JPY)</td>
                            <td class="text-right thick-line" width="5%"></td>
                          </tr>
                        </thead>
                        <tbody>
                          <!-- foreach ($order->lineItems as $line) or some such thing here -->
                          <tr v-for="task in receipt.cart" v-bind:key="task.hashid">
                            <!-- <router-link class="brand" to="/">KTBT</router-link> -->
                            <td><small><b-link :href="`/media_files/${task.media_file.hashid}/tasks?co=1`">{{ task.media_file.info.title }} - {{ task.media_file.info.channel }}</b-link> - {{ $t('lang.'+task.language_code_deepl) }} {{ $t('Sub') }}</small></td>
                            <!-- <td><b-link :to="{ name: 'tasks', params: { 'media_file_hashid': task.media_file.hashid } }">{{ task.media_file.info.title }} - {{ task.media_file.info.channel }}</b-link> - {{ $t('lang.'+task.language_code_deepl) }} {{ $t('Sub') }}</td> -->
                            <!-- <td class="text-center">{{ task.fee.total }}</td> -->
                            <!-- <td class="text-center">1</td> -->
                            <td class="text-right">{{ Number(task.fee.total).toLocaleString() }}</td>
                            <td class="text-center" style="font-size: 0.9rem;"><span class="clickable" @click="$emit('removeCart', task.hashid)"><b-icon icon="cart-dash" aria-hidden="true"></b-icon></span></td>
                          </tr>
                                      
                          <tr>
                            <td class="thick-line text-right">Total Fee</td>
                            <td class="thick-line text-right">{{ Number(receipt.quotation.total).toLocaleString() }}</td>
                            <td class="thick-line"></td>
                          </tr>
                          <!-- <tr>
                            <td class="no-line text-right">JP VAT(10.0%)</td>
                            <td class="no-line text-right">{{ quotation.tax_jp }}</td>
                            <td class="no-line"></td>
                          </tr>
                          <tr>
                            <td class="no-line text-right">Total</td>
                            <td class="no-line text-right">{{ quotation.total_including_tax_jp }}</td>
                            <td class="no-line"></td>
                          </tr> -->
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <a href="#" class="card-link">Card link</a>
            <b-link href="#" class="card-link">Another link</b-link> -->
          </b-card>
        </div>
        <div v-if="receipts.length > 0">
          <div>
            <b-list-group>
              <b-list-group-item v-for="(receipt) in receipts_displayed()" :key="receipt.hashid" class="flex-column align-items-start">
                
                <div>
                  <div class="d-flex w-100 justify-content-between clickable" @click="openReceipt(receipt)">
                    <div class="mb-1"><small>{{ receipt.session_params.line_items[0].price_data.product_data.name }} 他 全{{receipt.cart.length}}件</small> <!--{{receipt.hashid}}--></div>
                    <div><small class="clock text-muted">{{ new Date(receipt.updated_at) | date('yyyy-MM-dd')}}</small></div>
                  </div>
                </div>



              </b-list-group-item>
            </b-list-group>
          </div>
          <!-- <div class="overflow-auto"> -->
            <div class="mt-2">
              <b-pagination
                v-model="currentPage_r"
                :total-rows="receipts.length"
                :per-page="perPage_r"
                align="center"
                size="sm"
              ></b-pagination>
            </div>
          <!-- </div> -->
        </div>

        <!-- <div class="text-muted text-center" v-else>
          {{ $t('No_Projects') }}
        </div> -->
      </div>
      <!-- </div> -->
      
      <!-- </div> -->
    </div>
    <div id="full-width" class="clickable" v-if="!!receipt" @click="closeReceipt()">
      <div class="mb-3">
        <!-- <div class="row">
          <div class="col-12">
            <div class="invoice-title">
              <h5>Receipt</h5><h5 class="pull-right">Order # {{ receipt.hashid }}</h5>
            </div>
            <hr>
            <div class="row">
              <div class="col-6">
                <address>
                <strong>Billed To:</strong><br>
                  John Smith<br>
                  1234 Main<br>
                  Apt. 4B<br>
                  Springfield, ST 54321
                </address>
              </div>
              <div class="col-6 text-right">
                <address>
                  <strong>Shipped To:</strong><br>
                  Jane Smith<br>
                  1234 Main<br>
                  Apt. 4B<br>
                  Springfield, ST 54321
                </address>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <address>
                  <strong>Payment Method:</strong><br>
                  Visa ending **** 4242<br>
                  jsmith@email.com
                </address>
              </div>
              <div class="col-6 text-right">
                <address>
                  <strong>Order Date:</strong><br>
                  {{new Date(receipt.updated_at) | date('yyyy-MM-dd')}}<br><br>
                </address>
              </div>
            </div>
          </div>
        </div> -->
        
        <div class="row">
          <div class="col-md-12 small">
            <div class="panel panel-default">
              <!-- <div class="panel-heading">
                <h3 class="panel-title"><strong>Order summary</strong></h3>
              </div> -->
              <div class="panel-body">
                <div class="table-responsive">
                  <table class="table table-sm table-condensed">
                    <thead>
                      <tr>
                        <td class="thick-line">Item</td>
                        <!-- <td class="text-center thick-line" width="8%"><strong>Price</strong></td> -->
                        <!-- <td class="text-center thick-line" width="10%">Qty</td> -->
                        <!-- <td class="text-right thick-line" width="8%">Unit Fee(JPY)</td> -->
                        <!-- <td class="text-right thick-line" width="5%"></td> -->
                      </tr>
                    </thead>
                    <tbody>
                      <!-- foreach ($order->lineItems as $line) or some such thing here -->
                      <tr v-for="(item) in receipt.session_params.line_items" v-bind:key="item.hashid">
                        <!-- <router-link class="brand" to="/">KTBT</router-link> -->
                        <td><!--b-link :href="`/media_files/${receipt.hashid}/tasks`"-->{{ item.price_data.product_data.name }}<!--/b-link--> {{ $t('Sub') }}</td>
                        <!-- <td><b-link :to="{ name: 'tasks', params: { 'media_file_hashid': task.media_file.hashid } }">{{ task.media_file.info.title }} - {{ task.media_file.info.channel }}</b-link> - {{ $t('lang.'+task.language_code_deepl) }} {{ $t('Sub') }}</td> -->
                        <!-- <td class="text-center">{{ task.fee.total }}</td> -->
                        <!-- <td class="text-center">1</td>
                        <td class="text-right">{{ Number(item.price_data.unit_amount).toLocaleString() }}</td> -->
                        <!-- <td class="text-center" style="font-size: 0.9rem;"><span class="clickable" @click="$emit('removeCart', task.hashid)"><b-icon icon="cart-dash" aria-hidden="true"></b-icon></span></td> -->
                      </tr>
                                  
                      <!-- <tr>
                        <td class="thick-line"></td>
                        <td class="thick-line text-right">Total Fee</td>
                        <td class="thick-line text-right">{{ Number(receipt.quotation.total).toLocaleString() }}</td>
                      </tr> -->
                      <!-- <tr>
                        <td class="no-line text-right">JP VAT(10.0%)</td>
                        <td class="no-line text-right">{{ quotation.tax_jp }}</td>
                        <td class="no-line"></td>
                      </tr> -->
                      <!-- <tr>
                        <td class="no-line text-right">JP VAT(10.0%)</td>
                        <td class="no-line text-right">{{ quotation.tax_jp }}</td>
                        <td class="no-line"></td>
                      </tr>
                      <tr>
                        <td class="no-line text-right">Total</td>
                        <td class="no-line text-right">{{ quotation.total_including_tax_jp }}</td>
                        <td class="no-line"></td>
                      </tr> -->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      
    </div>
  </div>
</template>

<script>

  // import firebase from 'firebase/compat/app'; //追記
  // console.log(firebase.auth().currentUser)

  export default {
    name: 'MediaFiles',
    data() {
      return {
        src_url: '',
        sending: false,
        media_files: [],
        currentPage_mf: 1,
        perPage_mf: 10,
        receipts: [],
        currentPage_r: 1,
        perPage_r: 5,
        receipt: null,


        // loading: false,
      };
    },
    props: {
      languages: {
        required: false, // @todo ???
        type: Array
      },
      task_hashidsInCart: {
        required: false, // @todo ???
        type: Array
      },
    },
    computed: {
    },
    methods: {
      openReceipt(receipt){
        this.receipt = receipt;
      },
      closeReceipt(){
        this.receipt = null;
      },
      media_files_displayed(){
        // const totalRows = this.media_files.length
        // console.log(this.currentPage_mf, this.currentPage_mf);
        const start_idx = (this.currentPage_mf-1)*this.perPage_mf;
        const end_idx   = start_idx+this.perPage_mf;
        return this.media_files.slice( start_idx, end_idx);
      },
      receipts_displayed(){
        // const totalRows = this.media_files.length
        console.log(this.currentPage_r, this.currentPage_r);
        const start_idx = (this.currentPage_r-1)*this.perPage_r;
        const end_idx   = start_idx+this.perPage_r;
        return this.receipts.slice( start_idx, end_idx);
      },
      deleteMediaFile(media_file_hashid){
        this.$bvModal.msgBoxConfirm('削除してよろしいですか？').then(value => {
          console.log(value);
          if(value){
            this.axios.delete(
              '/media_files/' + media_file_hashid, 
              // {'hashid': media_file_hashid}
            ).then((response) => {
              this.media_files = response.data;
            });
          }
        }).catch(err => {
          // An error occurred
        })
        
      },
      sendUrl (src_url){
        this.sending = true;
        this.axios.post(
          '/media_files', 
          {'src_url': src_url}
        ).then((response) => {
          // this.media_files.unshift(
          //   { dummy: true, status: 'getting_ready'}
          // )
          this.src_url = '';
          this.sending = false;
          setTimeout(this.loadMediaFiles, 2000);

        });
      },
      loadMediaFiles (){
        this.axios.get(
          '/media_files',
          // {
          // //   xsrfHeaderName: 'X-CSRF-Token',
          //   // withCredentials: true,
          // //   // headers: { Authorization: `Bearer ${token}`, withCredentials: true },
          // }
        ).then((response) => {
          this.media_files = response.data;
          if(this.hasGettingReady()){
            setTimeout(this.loadMediaFiles, 10000);
            // this.loading = true;
          }
        }).catch(error => console.log(error));
      },
      loadReceipts (){
        this.axios.get(
          '/receipts',
          
        ).then((response) => {
          this.receipts = response.data;
        }).catch(error => console.log(error));
      },

      hasGettingReady() {
        const found = this.media_files.find((mf) => mf.status === 'getting_ready');
        return !!(found);
      }
    },
    computed: {
      yturlState() {
        if(this.src_url.length === 0) return null;
        const regexes = [
          /^https:\/\/w*\.?youtube\.com\/watch\?v=([0-9a-zA-Z_-]{11})/,
          /^https:\/\/w*\.?youtube\.com\/shorts\/([0-9a-zA-Z_-]{11})/,
        ]
        let found = regexes.find((regex) => regex.test(this.src_url));

        return !!found;
      }
    },
    // created () {

    // },
    created () {

      this.loadMediaFiles();
      // console.log(this.$route.params.code);
      // this.loadReceipts();

    },
    mounted () {
      

 

    },
    watch: {
    },
    filters: {
    },
  }

</script>

<style scoped>
.marquee {
	animation: marquee-anim 10s linear infinite;
}

@keyframes marquee-anim {
	0%   { transform: translate(100%, 0); }
	100% { transform: translate(-10%, 0); }
}

#full-width {
  z-index: 100;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  background: #fff;
  padding: 20px;
  position: absolute !important;;	
  top:0px;
  left:0px;
  width: 100%;

}

::placeholder{
  color: #ccc;
  /* font-style: italic; */
}
.dli-arrow-down {
  display: inline-block;
  vertical-align: middle;
  color: #666;
  line-height: 1;
  position: relative;
  width: 0.01em;
  height: 1.5em;
  background: currentColor;
}

.dli-arrow-down::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  border: 0.1em solid currentColor;
  border-top: 0;
  border-right: 0;
  transform: rotate(-45deg);
  transform-origin: bottom left;
  position: absolute;
  left: 50%;
  bottom: -0.2em;
  box-sizing: border-box;
}
</style>