<template>
  <div class="row">
    <div class="d-flex justify-content-center background">
      <div class="col-8 mt-5 pt-3">
        <div class="text-center sample"><h1><span style="background-color: #fff;"><small>あなたの</small> <b-img src="../assets/yt.png" width="210" class="mb-2" alt="YouTube動画"></b-img> <!--span class="text-danger">YouTube</span--><small> が国境を越える。</small></span></h1></div>
        <div class="text-center sample2 mt-4"><h3><span style="background-color: #fff;">ショートにも対応</span></h3></div>
        <div class="text-center mt-5"><h3>最新AI搭載。翻訳字幕を簡単DIY!</h3></div>
        <div class="mt-5">
          <b>AIの強力な支援で翻訳字幕をらくらく作成</b>
          <ul>
            <li>pyannote.audio による高精度な発話区間検出。</li>
            <li>OpenAI社 Whisper による高精度な文字起こし。</li>
            <li>DeepL SE社 DeepL による各言語へのナチュラルな翻訳。26言語対応。</li>
          </ul> 
        </div>
        <div class="mt-4">
          <b>納得＆リーズナブルな料金</b>
          <ul>
            <li>成果報酬型。成果物のVTTファイルダウンロード時に課金。つまりお試しもDIY作業も無料。最後に役に立った分だけお支払い。</li>
            <li>料金は発話区間（=字幕表示区間）に対して1言語1分当たり <s>￥150</s> <b class="text-danger">40%OFFセール中に付き￥90</b> が秒単位で算出されます。</li>
          </ul> 
        </div>
        <div class="mt-4">
          <b>始めるには？</b>
          <ul>
            <li>字幕を作りたい動画の、YouTubeもしくはYouTubeショートのURL（公開中のもの）を用意してください。</li>
            <li>そして、お持ちのGoogleアカウントでサインインしますと、あなたの字幕制作プロジェクトを作ることができます。ひとつの動画がひとつのプロジェクトになります。</li>
            <li>プロジェクトは文字起こしの作業から始まります。</li>
          </ul> 
        </div>
        <div class="text-center mt-5"><h4><span>すぐに始めましょう・・・</span></h4></div>

        <div class="mt-4">
          <b>その前にルールをひとつだけ！</b>
          <ul>
            <li>このシステムでは、字幕の中で扱われる文章は、文末記号すなわち日本語の場合は「。！？」でもって切り出され、翻訳の単位となります。</li>
          </ul> 
        </div>

        <div class="text-center mt-5"><h4><span>さあ、どうぞ！</span></h4></div>

        <div class="text-center mt-4">

          <a class="text-dark clickable" v-if="auth.currentUser !== null">
            <!-- <a href="/media_files"><small>{{ $t('Projects') }}</small></a> -->
            <b-button href="/media_files" variant="info">"Yourプロジェクト" に進む</b-button>
          </a>
          <a class="text-dark clickable" @click="signIn()" v-else>
            <button type="button" class="login-with-google-btn" >
              Sign in with Google
            </button>
          </a>

          <!-- <button type="button" class="login-with-google-btn" >
            Sign in with Google
          </button> -->
        </div>
        <!-- <button type="button" class="btn btn-info btn-lg btn-block mt-5"></button> -->
        <!-- <div class="mt-5">ユーザに必要な環境</div>
        <ul>
          <li>Googleのアカウントを所有していること。</li>
          <li>最新のモダンWEBブラウザ。</li>
          <li>1280x1080以上のモニター。</li>
        </ul> -->
      </div>
    </div>
    
  </div>
</template>

<script>

import {auth, signInGoogle} from '../myfirebase.js';

export default {
  name: 'Home',

  data () {
    return {
      auth: auth,//20230818
    }
  },
  methods: {

    signIn(){
      signInGoogle().then((result)=>{
        // console.log('successfully sign in.');

        window.localStorage.setItem('access_token', result.user.accessToken);
        this.axios.put(
          '/auth', 
          { id_token: result.user.accessToken }
        ).then((response) => {

          window.location.href = '/media_files';
        });
      });
    },
    
  },

  watch: {
  }
}
</script>

<style>
/* .fuchidori {
  color: #000;
  -webkit-text-stroke: 3px #FFF;
} */
.sample {
  color: #222;
  /* text-shadow: 2px 3px 5px #aaa; */
  transform: rotate(2deg);
}
.sample2 {
  color: #222;
  /* text-shadow: 2px 3px 5px #aaa; */
  transform: rotate(-1deg);
}
.background {
  width: 100%;
  /* height: 90vh; */
  height: 1280px;
  background-image: url("../assets/translation-7774314_1920.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-color:rgba(255,255,255,0.9);
  /* background-blend-mode:lighten; */
  background-blend-mode: saturation;
  /* filter:grayscale(50%) */
  
}

.login-with-google-btn {
  transition: background-color .3s, box-shadow .3s;
    
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
  
  /* &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }
  
  &:active {
    background-color: #eeeeee;
  }
  
  &:focus {
    outline: none;
    box-shadow: 
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px #c8dafc;
  }
  
  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
  } */
}
</style>