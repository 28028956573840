<template>
    <draggable class="dragArea" tag="ul" :list="tasks" :group="{ name: 'g1' }" handle=".handle">
        <!-- :options="{disabled : true}" -->
        <li v-for="(t, idx) in tasks" :key="t.hashid">

            <b-overlay :show="showOverlay === t.hashid" rounded="sm">

                <div class="pt-3 pb-3 d-flex justify-content-between" :class="{'handle': t.demand === 'translation' && !t.fee.paid}">
                    <div class="bd-highlight" style="width: 50%">
                        <div class="bd-highlight">
                            <span class="text-end mr-1"><i class="bi bi-arrows-move" v-if="!t.fee.paid"></i></span>
                            <span>{{$t(t.demand)}}:
                                <span class="clock">{{ t.language_code_deepl }}</span> 
                                <span> {{ $t('lang.'+t.language_code_deepl) }} </span> 
                                <!-- <span class="clock">[{{t.hashid}}]</span> -->
                                <b-badge href="#" variant="info" v-if="t.fee.paid">お支払済み</b-badge>
                            </span>
                        </div>
                        <div class="bd-highlight">
                            <!-- <div class="d-flex justify-content-between"> -->
                            <div>
                                <b-progress 
                                    :max="t.progress.subtitles.active" 
                                    height="0.5rem" :variant="!progressCompleted(t.progress) ? 'danger' : 'success'" 
                                    :animated="!progressCompleted(t.progress)"
                                >
                                    <b-progress-bar :value="t.progress.subtitles.text_filled_active">
                                    </b-progress-bar>
                                </b-progress>
                            </div>
                            <div>
                                <small>{{ $t('Filled') }}: <span class="clock">{{ t.progress.subtitles.text_filled_active }}</span> / {{ $t('Total') }}: <span class="clock">{{ t.progress.subtitles.active }}</span> = {{ $t('Progress') }}: <span v-if="t.progress.subtitles.active !== 0" class="clock" :class="{'text-danger': (t.progress.subtitles.text_filled_active / t.progress.subtitles.active) < 1.0}">{{ (t.progress.subtitles.text_filled_active / t.progress.subtitles.active * 100).toFixed(2) }} %</span><span v-else> - %</span></small>
                                &nbsp;<small>{{$t('VoiceActiveDuration')}} : <span class="clock">{{t.fee.duration}} s</span></small>
                                &nbsp;<span class="small text-secondary">{{ $t('updated_at') }}: <span class="clock">{{ new Date(t.progress.subtitles.updated_at) | date('yyyy-MM-dd HH:mm') }}</span></span>
                            </div>
                            
                            <!-- </div> -->
                        </div>
                    </div>
                    <div class="bd-highlight mt-2" v-if="!t.fee.paid">

                        <!-- <small>{{ t.fee.paid ? 'paid' : '' }}</small> -->

                        <b-button size="sm" class="mr-1 align-middle" :disabled="!progressCompleted(parentTask.progress) || task_hashidsInCart.includes(t.hashid)" variant="outline-success" @click="translateWholeTask(t, parentTask)"><small><b-icon icon="translate" aria-hidden="true"></b-icon>&nbsp;{{ $t('Batch_Translation') }}: <span class="clock">{{ parentTask.language_code_deepl }}</span> →&nbsp;<span class="clock">{{ t.language_code_deepl }}</span></small></b-button>
                        <b-button size="sm" class="mr-1 align-middle" :disabled="!progressInitialized(t.progress) || task_hashidsInCart.includes(t.hashid)"  variant="outline-info" @click="() => $router.push({ name: 'task', params: { media_file_hashid: media_file.hashid, task_hashid: t.hashid } })"><small><b-icon icon="pencil" aria-hidden="true"></b-icon>&nbsp;{{ $t('Edit') }}</small></b-button>
                        
                        <b-button size="sm" class="mr-3 align-middle" :disabled="!progressCompleted(t.progress)" @click="$emit('addCart', t.hashid)" variant="outline-primary"
                        v-if="!task_hashidsInCart.includes(t.hashid)">
                            <small><b-icon icon="cart-plus" aria-hidden="true"></b-icon>&nbsp;{{ $t('add_to_cart') }} ({{t.fee.total}} 円)</small>
                        </b-button>
                        <b-button size="sm" class="mr-3 align-middle" :disabled="!progressCompleted(t.progress)" @click="$emit('removeCart', t.hashid)" variant="primary"
                        v-else>
                            <small><b-icon icon="cart-dash" aria-hidden="true"></b-icon>&nbsp;{{ $t('remove_from_cart') }} ({{t.fee.total}} 円)</small>
                        </b-button>

                        <span class="mr-3 align-middle" @click="removeTask(idx)"><b-icon icon="trash" aria-hidden="true"></b-icon></span>
                    </div>
                    <div class="bd-highlight mt-2" v-else>
                        <b-button size="sm" class="mr-3 align-middle" variant="outline-primary"  @click="downloadSubs(t.hashid, t.language_code_deepl, 'vtt')">
                            <small><b-icon icon="download" aria-hidden="true"></b-icon>&nbsp;{{ $t('Download_Subs') }}</small>
                        </b-button>
                    </div>
                </div>
            </b-overlay>
            <nestedTasks :task_hashidsInCart="task_hashidsInCart" :media_file="media_file" :parentTask="t" :tasks="t.tasks" @changeCurrentTask="changeCurrentTask" @addCart="$listeners['addCart']" @removeCart="$listeners['removeCart']" />


        </li>
    </draggable>
</template>
<script>
import draggable from "vuedraggable";

export default {
    name: "nestedTasks",

    data() {
      return {
        showOverlay: null,
      };
    },


    props: {
        parentTask: {
            required: true,
            type: Object,
        },
        tasks: {
            required: true,
            type: Array
        },
        media_file: {
            required: true,
            type: Object,

        },
        task_hashidsInCart:{
            required: false,
            type: Array

        }

        // currentTask_hashid: {
        //     type: String,
        // }
    },
    
    components: {
        draggable
    },
    // name: "nested-draggable",

    computed: {

        // progressCompleted: function(progress){
        //     return !(progress.subtitles.active !== 0 && 
        //         (progress.subtitles.text_filled_active / progress.subtitles.active) < 1.0)
        // },

    },
    mounted () {
        // this.translate();
    },
    methods: {
        progressCompleted: function(progress){
            // return true;
            const completed = progress.subtitles.active !== 0 && (progress.subtitles.text_filled_active / progress.subtitles.active) >= 1.0;
            return completed;
        },
        progressInitialized: function(progress){
            const initialized = progress.subtitles.active > 0;
            return initialized;
        },
        changeCurrentTask: function(hashid){
            // console.log(hashid);
            this.$emit('changeCurrentTask', hashid);
            
        },
        translateWholeTask: function(task, parentTask){
            this.$bvModal.msgBoxConfirm(parentTask.language_code_deepl+'の字幕を翻訳し'+task.language_code_deepl+"の字幕を生成します。"+
            task.language_code_deepl+'の字幕があれば生成されたものに上書きされます。よろしいですか？').then(value => {
                console.log(task);
                if(value){
                    this.showOverlay = task.hashid;
                    this.axios.post(
                        '/translate_whole_task', 
                        {'task_hashid': task.hashid}
                    ).then((response) => {
                        task.progress = response.data;
                    // console.log(this.showOverlay[task.hashid])
                        this.showOverlay = null;
                        // console.log(this.showOverlay);
                    });
                }
            }).catch(err => {
            // An error occurred
            })

        },
        downloadSubs: function(task_hashid, lang="JA", fmt='vtt'){
            this.axios.get(
                '/tasks/'+task_hashid+'/subtitles', 
                {params: {'fmt': fmt}, responseType: "blob",}
            ).then((response) => {
                let mimeType = response.headers["content-type"];
                const blob = new Blob([response.data], { type: mimeType });
                saveAs(blob, `${this.media_file.hashid}-${lang}.${fmt}`);
            });
        },
        addCart: function(task_hashid){
            this.axios.post(
                '/cart',
                {'task_hashid': task_hashid}
            ).then((response) => {
                console.log(response);
            });
        },
        removeCart: function(task_hashid){
            this.axios.delete(
                '/cart/'+task_hashid,
            ).then((response) => {
                console.log(response);
            });
        },
        removeTask: function(idx){
            this.$bvModal.msgBoxConfirm('削除してよろしいですか？').then(value => {
                if(value){
                    this.tasks.splice(idx, 1)
                }
            }).catch(err => {
            // An error occurred
            })
        },

    }
};
</script>
<style scoped>
ul {
    min-height: 40px;
    /* outline: 1px dashed; */
    outline: 2px solid #999;
    cursor: pointer;
}
li {
    list-style:  none;
}
</style>