var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"d-flex justify-content-center background"},[_c('div',{staticClass:"col-8 mt-5 pt-3"},[_c('div',{staticClass:"text-center sample"},[_c('h1',[_c('span',{staticStyle:{"background-color":"#fff"}},[_c('small',[_vm._v("あなたの")]),_vm._v(" "),_c('b-img',{staticClass:"mb-2",attrs:{"src":require("../assets/yt.png"),"width":"210","alt":"YouTube動画"}}),_vm._v(" "),_c('small',[_vm._v(" が国境を越える。")])],1)])]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"text-center mt-4"},[(_vm.auth.currentUser !== null)?_c('a',{staticClass:"text-dark clickable"},[_c('b-button',{attrs:{"href":"/media_files","variant":"info"}},[_vm._v("\"Yourプロジェクト\" に進む")])],1):_c('a',{staticClass:"text-dark clickable",on:{"click":function($event){return _vm.signIn()}}},[_c('button',{staticClass:"login-with-google-btn",attrs:{"type":"button"}},[_vm._v(" Sign in with Google ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center sample2 mt-4"},[_c('h3',[_c('span',{staticStyle:{"background-color":"#fff"}},[_vm._v("ショートにも対応")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mt-5"},[_c('h3',[_vm._v("最新AI搭載。翻訳字幕を簡単DIY!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5"},[_c('b',[_vm._v("AIの強力な支援で翻訳字幕をらくらく作成")]),_c('ul',[_c('li',[_vm._v("pyannote.audio による高精度な発話区間検出。")]),_c('li',[_vm._v("OpenAI社 Whisper による高精度な文字起こし。")]),_c('li',[_vm._v("DeepL SE社 DeepL による各言語へのナチュラルな翻訳。26言語対応。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('b',[_vm._v("納得＆リーズナブルな料金")]),_c('ul',[_c('li',[_vm._v("成果報酬型。成果物のVTTファイルダウンロード時に課金。つまりお試しもDIY作業も無料。最後に役に立った分だけお支払い。")]),_c('li',[_vm._v("料金は発話区間（=字幕表示区間）に対して1言語1分当たり "),_c('s',[_vm._v("￥150")]),_vm._v(" "),_c('b',{staticClass:"text-danger"},[_vm._v("40%OFFセール中に付き￥90")]),_vm._v(" が秒単位で算出されます。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('b',[_vm._v("始めるには？")]),_c('ul',[_c('li',[_vm._v("字幕を作りたい動画の、YouTubeもしくはYouTubeショートのURL（公開中のもの）を用意してください。")]),_c('li',[_vm._v("そして、お持ちのGoogleアカウントでサインインしますと、あなたの字幕制作プロジェクトを作ることができます。ひとつの動画がひとつのプロジェクトになります。")]),_c('li',[_vm._v("プロジェクトは文字起こしの作業から始まります。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mt-5"},[_c('h4',[_c('span',[_vm._v("すぐに始めましょう・・・")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('b',[_vm._v("その前にルールをひとつだけ！")]),_c('ul',[_c('li',[_vm._v("このシステムでは、字幕の中で扱われる文章は、文末記号すなわち日本語の場合は「。！？」でもって切り出され、翻訳の単位となります。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mt-5"},[_c('h4',[_c('span',[_vm._v("さあ、どうぞ！")])])])
}]

export { render, staticRenderFns }