<template>
  <div class="row">
    <!-- <div class="d-flex justify-content-center"> -->
      <div class="col-12 mt-5 pt-3">
      <h4>特定商取引法に基づく表記</h4>
        <table class="table table-bordered">
          <tbody>
            <tr>
              <th scope="row">販売業社の名称</th>
              <td>前田真吾</td>
            </tr>
            <tr>
              <th scope="row">所在地</th>
              <td>請求があったら遅滞なく開示します。</td>
            </tr>
            <tr>
              <th scope="row">電話番号</th>
              <td>請求があったら遅滞なく開示します。</td>
            </tr>
            <tr>
              <th scope="row">メールアドレス</th>
              <td>maeda.ktbt@gmail.com</td>
            </tr>
            <tr>
              <th scope="row">運営統括責任者</th>
              <td>前田真吾</td>
            </tr>
            <tr>
              <th scope="row">追加手数料等の追加料金</th>
              <td>ありません。</td>
            </tr>
            <tr>
              <th scope="row">交換および返品（返金ポリシー）</th>
              <td>当方に瑕疵がある場合は個別に返金いたします。</td>
            </tr>
            <tr>
              <th scope="row">引渡時期</th>
              <td>決済後すぐにダウンロードいただけます。</td>
            </tr>
            <tr>
              <th scope="row">受け付け可能な決済手段</th>
              <td>クレジットカード</td>
            </tr>
            <tr>
              <th scope="row">決済期間</th>
              <td>ただちに処理されます。</td>
            </tr>
            <tr>
              <th scope="row">販売価格</th>
              <td>各翻訳字幕ごとに、ダウンロードボタンに表示されます。(消費税込)</td>
            </tr>
          </tbody>
        </table>
      <!-- </div> -->
    </div>
    
  </div>
</template>

<script>


export default {
  name: 'TokutoriJP',

  data () {
    return {
    }
  },
  methods: {
    
  },

  watch: {
  }
}
</script>

<style>

</style>