<template>
  <div class="row">
    <div class="col-12">

      <div id="tasks">
        <template>
          <b-breadcrumb class="small">
            <b-breadcrumb-item :to="{name: 'media_files'}">{{ $t('Projects') }}</b-breadcrumb-item>
            <b-breadcrumb-item v-if="!!media_file.info" active>{{ media_file.info.title }}</b-breadcrumb-item>
          </b-breadcrumb>
        </template>
        <div  class="row">
          <div class="col-sm-4">
            <div class="">
              <!-- pl-3 ml-4 -->
              <b-img  v-if="!!media_file.info" fluid center :alt="media_file.info.title" :src="media_file.info.thumbnail"></b-img>
            </div>
          </div>
          <div class="col-sm-8">
            <div style="word-wrap: break-word;" v-if="media_file.info">
              <div>
                <small class="subheading">{{ $t('Title') }}</small>
              </div>
              <div class=""><a :href="media_file.info.webpage_url" target="_blank">{{media_file.info.title}}</a></div>
              <div>
                <small class="subheading">{{ $t('Channel') }}</small>
              </div>
              <div class=""><a :href="media_file.info.channel_url" target="_blank">{{media_file.info.channel}}</a></div>
              <div>
                <small class="subheading">{{ $t('Duration') }}</small>
              </div>
              <div class="clock">{{media_file.info.duration_string}}</div>
              <div>
                <small class="subheading">{{ $t('Description') }}</small>
              </div>
              <!-- <pre class="font-weight-normal"><read-more more-str="read more" :text="media_file.info.description" link="#" less-str="read less" :max-chars="120"></read-more></pre> -->
              <!-- <vue-read-more-smooth :text="media_file.info.description"></vue-read-more-smooth> -->
              <VueReadMoreSmooth :lines="3" text="more" text-less="less" :g-lines="2"><pre style="white-space: pre-wrap;">{{ media_file.info.description }}</pre></VueReadMoreSmooth>
            </div>
          </div>

          <Plan :media_file="media_file" :task_hashidsInCart="task_hashidsInCart" :plan="media_file.plan_ex" :languages="languages"  @addTranslationTask="addTranslationTask" @addCart="$listeners['addCart']" @removeCart="$listeners['removeCart']" />

        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  // import BigNumber  from "bignumber.js";

  import VueReadMoreSmooth from "vue-read-more-smooth";
  // import nestedDraggable from "./infra/nested";
  // import VueOnlineOffline from 'vue-online-offline';

  import Plan from './Plan.vue';

  export default {
    name: 'Tasks',
    // components: {VueOnlineOffline},
    components: { 
      Plan,
      VueReadMoreSmooth,
    },
    data() {
      return {
        // tasks: {},
        // plan: [],

        media_file: {},

        languages: [],
      };
    },
    props: {
      // languages: {
      //   required: false, // @todo ???
      //   type: Array
      // },
      task_hashidsInCart: {
        required: false, // @todo ???
        type: Array
      },
    },
    methods: {
      // addCart: function(task_hashid){
      //   this.$emit('addCart', task_hashid);
      // },
      // removeCart: function(task_hashid){
      //   this.$emit('removeCart', task_hashid);
      // },
      debug: (value) => {
        console.debug(value)
      },
      
      addTranslationTask: function (language_code_deepl) {
        if(language_code_deepl){
          this.axios.post(
            '/media_files/'+this.media_file.hashid+'/tasks', 
            {'language_code_deepl': language_code_deepl}
          ).then((response) => {
            // console.log(this.media_file);
            const task_added = response.data;
            this.media_file.plan_ex[0].tasks.push({
              demand: task_added.demand,
              hashid: task_added.hashid,
              language_code_deepl: task_added.language_code_deepl,
              progress: task_added.progress,
              tasks: [],
            });
          });
        }
      },

      loadMediaFile: function(){
        this.axios.get(
          '/media_files/'+this.$route.params.media_file_hashid
        ).then((response) => {
          // console.log(response.data);
          this.media_file = response.data;
          

        }).catch(error => console.debug(error));
      }

    },

    computed: {
      // https://ytakeuchi.jp/?p=1130
      plan_ex_clone () {
        if(!this.media_file?.plan_ex) return {};
        // 非参照のコピーを作る
        return JSON.parse(JSON.stringify(this.media_file.plan_ex));
      }
    },

    created () {
      this.axios.get(
        '/languages'
      ).then((response) => {
        this.languages = response.data;
        // this.currentSettings.transcriptionLanguageCode = this.languagesUniqByCodeDeepl
      });
      this.loadMediaFile();

    },

    watch: {
      // 'media_file.plan': {
      //   handler: _.debounce(function(new_plan, old_plan) {
      //     // console.log(new_media_file);
      //     this.axios.put(
      //       '/media_files/' + this.media_file.hashid,
      //       {
      //         // 'language_code_deepl': new_media_file.language_code_deepl,
      //         'plan': new_plan,
              
      //       }
      //     ).then((response) => {
      //       // this.$router.go({path: this.$router.currentRoute.path, force: true})

      //       // this.media_file.plan = response.data.plan;
            
      //     });
      //   }, 500),
      //   deep: true,
      //   immediate: false,
      // },
      plan_ex_clone: {
        handler: function(new_plan_ex, old_plan_ex) {
          // console.log(JSON.stringify(new_plan_ex));
          // console.log(JSON.stringify(old_plan_ex));
          // console.log(!!old_plan_ex);
          // console.log(JSON.stringify(new_plan_ex) === JSON.stringify(old_plan_ex));
          
          if(JSON.stringify(new_plan_ex) === JSON.stringify(old_plan_ex)) return;
          this.axios.put(
            '/media_files/' + this.media_file.hashid,
            {
              // 'language_code_deepl': new_media_file.language_code_deepl,
              'plan': new_plan_ex,
              
            }
          ).then((response) => {
            this.loadMediaFile();
          });
        },
        deep: true,
        immediate: false,
      },
    },
    filters: {
    },
    // created () {
    //   window.addEventListener("beforeunload", this.confirmSave);
    // },
    // destroyed () {
    //   window.removeEventListener("beforeunload", this.confirmSave);
    // },
  }

</script>

<style>

  

  




  /* https://qiita.com/nezurika/items/ea3ae9a3e9ec3c158528 */




.subheading {
  border-left: solid 2px silver;/*左線*/
  padding-inline: 4px;
  margin-bottom: 2px;
}
::placeholder{
  color: #ccc;
}


</style>