<template>
  <div id="subtitleIndex" class="item subtitle clickable animeS" >
    <div class="clock hashnum"><small><i class="bi bi-hash"></i>{{i+1}}</small></div>
    <div class="start clock text-right">{{subtitle.start|mcsscff}}</div>
    <div class="hyphen text-center"> - </div>
    <div class="end clock text-right">{{subtitle.end|mcsscff}}</div>
    <div class="text text-truncate" v-if="subtitle.text">{{subtitle.text}}</div>
    <div class="text text-truncate text-black-50" v-else><i>No text</i></div>
  </div>
</template>


<script>
  

  export default {
    name: 'SubtitleIndex',
    // components: {VueOnlineOffline},
    data() {
      return {
        // subtitles: []
        query: ''
      };
    },
    props: ['subtitle', 'i'],
    
    methods: {
      debug: (val) => {
        console.debug(val);
      },

      // 
    },

    computed: {
    },

    mounted () {
      
    },

    watch: {
    },
    filters: {
    
    },
    created () {
      // window.addEventListener("beforeunload", this.confirmSave);
    },
    destroyed () {
      // window.removeEventListener("beforeunload", this.confirmSave);
    },
  }

</script>

<style scoped>
  
  /* @import url("nodevideo.js/dist/video-js.css"); */

  
  /* #video-player.xs {
    width: 480px;
    height: 270px;
  } */

  /* #ysubtitles {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-flow: column;
  } */

  



</style>