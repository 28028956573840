<template>
  
</template>

<script>

export default {
  name: 'Home',

  data () {
    return {
    }
  },
  created: function(){

    // console.log(window.navigator.language);
    let lang = this.$i18n.locale;//window.navigator.language.slice(0, 2);
    if(lang != 'ja') lang = 'en';
    window.location.href = `/${lang}`;
  },
  methods: {
    
  },

  watch: {
  }
}
</script>

<style>

</style>