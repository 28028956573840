<template>


    <div class="col-sm-12">
      <!-- {{ task_hashidsInCart }} -->
      <!-- <h3>字幕作成プラン</h3> -->
      <ul v-if="!!plan" class="" style="padding-inline-start: 0px">
        <!-- mr-5 -->
        <li style="list-style: none;">
          <div class="pt-3 pb-3 d-flex justify-content-between bd-highlight">
            <!-- pt-5 -->
            <div class="bd-highlight" style="width: 50%">
              <div class="bd-highlight">
                <span>{{$t(plan[0].demand)}}:
                  <span class="clock">{{ plan[0].language_code_deepl }}</span> 
                  <span> {{ $t('lang.'+plan[0].language_code_deepl)}} </span> 
                  <!-- <span class="clock">[{{plan[0].hashid}}]</span> -->
                  <b-badge href="#" variant="info" v-if="plan[0].fee.paid">お支払済み</b-badge>
                </span>
              </div>
              <div>
                <b-progress :max="plan[0].progress.subtitles.active" height="0.5rem" :variant="!progressCompleted(plan[0].progress) ? 'danger':  'success'" :animated="!progressCompleted(plan[0].progress)">
                  <b-progress-bar :value="plan[0].progress.subtitles.text_filled_active">
                  </b-progress-bar>
                </b-progress>
              </div>
              <div>
                <small>{{ $t('Filled') }}: <span class="clock">{{ plan[0].progress.subtitles.text_filled_active }}</span> / {{ $t('Total') }}: <span class="clock">{{ plan[0].progress.subtitles.active }}</span> = {{ $t('Progress') }}: 
                  <span v-if="plan[0].progress.subtitles.total !== 0" 
                  class="clock" :class="{'text-danger': !progressCompleted}">{{ (plan[0].progress.subtitles.text_filled_active / plan[0].progress.subtitles.active * 100).toFixed(2) }} %</span><span v-else> - %</span>
                  &nbsp;{{$t('VoiceActiveDuration')}} : <span class="clock">{{plan[0].fee.duration}} s</span>
                  &nbsp;<span class="text-muted">{{ $t('updated_at') }}: <span class="clock">{{ new Date(plan[0].progress.subtitles.updated_at) | date('yyyy-MM-dd HH:mm')}}</span></span>
                </small>
              </div>
            </div>
            <div class="bd-highlight mt-2" v-if="!plan[0].fee.paid">
                <!-- <b-button size="sm" class="mr-3 align-middle" :disabled="!progressCompleted(plan[0].progress)" variant="outline-success"><small><b-icon icon="translate" aria-hidden="true"></b-icon>&nbsp;一括翻訳: <span class="clock">{{ task.language_code_deepl }}</span> →&nbsp;<span class="clock">{{ t.language_code_deepl }}</span></small></b-button> -->
                
                <!-- <small>{{ plan[0].fee.paid ? 'paid' : '' }}</small> -->
                <span v-b-tooltip.hover title="Initialize subs to activate.">
                  <b-button size="sm" class="mr-1 align-middle" :disabled="!progressInitialized(plan[0].progress) || task_hashidsInCart.includes(plan[0].hashid)" variant="outline-info" @click="() => $router.push({ name: 'task', params: { media_file_hashid: media_file.hashid, task_hashid: plan[0].hashid } })">
                    <small><b-icon icon="pencil" aria-hidden="true"></b-icon>&nbsp;{{ $t('Edit') }}</small>
                  </b-button>
                </span>
                <span v-b-tooltip.hover title="Fill all subs to activate.">
                  <b-button size="sm" class="mr-1 align-middle" :disabled="!progressCompleted(plan[0].progress)" @click="$emit('addCart', plan[0].hashid);" variant="outline-primary" 
                  v-if="!task_hashidsInCart.includes(plan[0].hashid)">
                    <small><b-icon icon="cart-plus" aria-hidden="true"></b-icon>&nbsp;{{ $t('add_to_cart') }} ({{plan[0].fee.total}} 円)</small>
                  </b-button>
                  <b-button size="sm" class="mr-1 align-middle" :disabled="!progressCompleted(plan[0].progress)" @click="$emit('removeCart', plan[0].hashid);" variant="primary" 
                  v-else>
                    <small><b-icon icon="cart-dash" aria-hidden="true"></b-icon>&nbsp;{{ $t('remove_from_cart') }} ({{plan[0].fee.total}} 円)</small>
                  </b-button>
                </span>
                <!-- <span class="mr-3 align-middle" @click="tasks.splice(idx, 1)"><b-icon icon="trash" aria-hidden="true"></b-icon></span> -->
                <!-- {{plan[0].hashid}} -->
            </div>

            <div class="bd-highlight mt-2" v-else>
              <b-button size="sm" class="mr-3 align-middle" @click="downloadSubs(plan[0].hashid, plan[0].language_code_deepl, 'vtt')" variant="outline-primary">
                <small><b-icon icon="download" aria-hidden="true"></b-icon>&nbsp;{{ $t('Download_Subs') }}</small>
              </b-button>
            </div>
          </div>
          
        </li>
        <nestedTasks v-if="!!plan[0]" :task_hashidsInCart="task_hashidsInCart" :media_file="media_file" :parentTask="plan[0]" :tasks="plan[0].tasks" @addCart="$listeners['addCart']" @removeCart="$listeners['removeCart']"></nestedTasks>  
        <!-- @changeCurrentTask="changeCurrentTask" /> --> 
      </ul>
      <div class="">
        <!-- ml-5 mr-5 -->
        <b-input-group size="sm">
          <b-form-select v-model="language_code_deepl" size="sm">
            <b-form-select-option :value="language.code_deepl" v-for="language in selectableLanguages" :key="language.code_deepl">{{language.code_deepl}} {{ $t('lang.'+language.code_deepl) }}</b-form-select-option>
          </b-form-select>
          <b-input-group-append>
            <b-button size="sm" variant="outline-secondary" @click="$emit('addTranslationTask', language_code_deepl); language_code_deepl = null">{{ $t('message.add_translation_task') }}</b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>
</template>


<script>
  

  import nestedTasks from "./infra/nestedTasks";
  import saveAs from "file-saver";

  // import { compareAsc, format } from 'date-fns'


  export default {
    name: 'Plan',
    // components: {VueOnlineOffline},

    components: { 
      nestedTasks,
    },

    data() {
      return {
        language_code_deepl: null,
        // task_hashidsInCart: [],
      }
    },

    props: {
      plan: {
        required: false, // @todo ???
        type: Array
      },
      languages: {
        required: false, // @todo ???
        type: Array
      },
      media_file: {
        required: true, // @todo ???
        type: Object
      },
      task_hashidsInCart: {
        required: true, // @todo ???
        type: Array
      },
    },

    methods: {

      // addCart: function(task_hashid){
      //   this.$emit('addCart', task_hashid);
      // },
      // removeCart: function(task_hashid){
      //   this.$emit('removeCart', task_hashid);
      // },

      progressCompleted: function(progress){
            // return true;
          const completed = progress.subtitles.active !== 0 && (progress.subtitles.text_filled_active / progress.subtitles.active) >= 1.0;
          return completed;
      },
      progressInitialized: function(progress){
          const initialized = progress.subtitles.active > 0;
          return initialized;
      },
      
      downloadSubs: function(task_hashid, lang="JA", fmt='vtt'){
        this.axios.get(
          '/tasks/'+task_hashid+'/subtitles', 
          {params: {'fmt': fmt}, responseType: "blob",}
        ).then((response) => {
          let mimeType = response.headers["content-type"];
          const blob = new Blob([response.data], { type: mimeType });
          saveAs(blob, `${this.media_file.hashid}-${lang}.${fmt}`);
        });
      },
      // addCart: function(task_hashid){
      //   this.axios.post(
      //     '/cart',
      //     {'task_hashid': task_hashid}
      //   ).then((response) => {
      //     this.task_hashidsInCart = response.data;
      //     console.log(response);
      //   });
      // },
      // removeCart: function(task_hashid){
      //   this.axios.delete(
      //     '/cart/'+task_hashid,
      //   ).then((response) => {
      //     this.task_hashidsInCart = response.data;
      //     console.log(response);
      //   });
      // },
    },

    computed: {

      // progressCompleted: function(){
      //   return !(this.plan[0].progress.subtitles.active !== 0 && 
      //          (this.plan[0].progress.subtitles.text_filled_active / this.plan[0].progress.subtitles.active) < 1.0)
      // },

      selectableLanguages: function(){
        // console.log(this.plan.flatMap(p => p.tasks));
        // if(!!!this.media_file) return [];
        let _k = (tasks) => {
          let language_code_deepls = [];
          if(!!tasks){
            tasks.forEach(task => {
              language_code_deepls.push(task.language_code_deepl);
              if(Array.isArray(task.tasks)){
                language_code_deepls = language_code_deepls.concat(_k(task.tasks));
              }
            });
          }
          return language_code_deepls;
        };
        let languages_in_plan = _k(this.plan);
        // console.log(languages_in_plan);
        return this.languages.filter((l) => !!!languages_in_plan.includes(l.code_deepl));
      },
    },

    mounted () {
      
    },

    watch: {
    },
    filters: {
    
    },
    created () {
      // window.addEventListener("beforeunload", this.confirmSave);
    },
    destroyed () {
      // window.removeEventListener("beforeunload", this.confirmSave);
    },
  }

</script>

<style scoped>
  


</style>