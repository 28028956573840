<template>
  <main class="pb-5 container-fluid">
    <Header @changeLocale="changeLocaleEvent($event)" :task_hashidsInCart="task_hashidsInCart" @toggleCart="toggleCart" />
    <div class="accordion-body" :class="{show: cartOpen}">
      <div class="accordion-body-inner">
        <Cart :cart="cart" :quotation="quotation" @removeCart="removeCart" @loadCart="loadCart" />
      </div>
    </div>
    <div id="top"></div>
    <router-view :task_hashidsInCart="task_hashidsInCart" @addCart="addCart" @removeCart="removeCart" />
    <footer class="text-center text-muted">
      <!-- Copyright -->
      <div v-if="$i18n.locale === 'ja'" class="text-center mt-3"> <b-link to="/tokutori_jp">特定商取引法に基づく表記</b-link></div>
      <div class="text-center p-3">© 2024 Copyright: KTB-Tools</div>
      <!-- Copyright -->
    </footer>
  </main>
  <!-- Footer -->

<!-- Footer -->
</template>

<script>

// import { projectAuth } from './auth.js'

import Header from '@/components/Header.vue'
import Cart from '@/components/Cart.vue'
// class task_hashidsInCart{
//   constructor(tasks = []) {
//     this.tasks = tasks;
//   }
// }

export default {
  name: 'App',
  data () {
    return {
      task_hashidsInCart: [],
      cart: [],
      quotation: {},
      cartOpen: this.$route.query.co === 1,
    };
  },
  components: {
    Header,
    Cart,
  },
  methods: {
    toggleCart: function(){
      // window.alert(this.cartOpen);
      this.cartOpen = !this.cartOpen;
    },
    addCart: function(task_hashid){
      // console.log('addCart====');
      this.axios.post(
        '/cart',
        {'task_hashid': task_hashid}
      ).then((response) => {
        this.task_hashidsInCart = response.data;
        console.log(response);
      });
    },
    removeCart: function(task_hashid){
      // console.log('removeCart====');
      this.axios.delete(
        '/cart/'+task_hashid,
      ).then((response) => {
        this.task_hashidsInCart = response.data;
        console.log(response);
      });
    },
    loadCart: function(){
      this.axios.get(
        '/cart'
      ).then((response) => {
        this.task_hashidsInCart = response.data;
      });
    },
  },
  created (){
    const locale = localStorage.getItem('locale');
    this.$i18n.locale = 'ja';
    if(!!locale){
      this.$i18n.locale = locale;
    }
    this.loadCart();


  },
  watch : {
    '$i18n.locale': (newLocale, oldLocale) => {
      localStorage.setItem('locale', newLocale);
    },
    'task_hashidsInCart': {
      handler: function(newV, oldV) {   
        this.axios.get(
          '/cart/tasks'
        ).then((response) => {
          this.cart = response.data;
          this.axios.get(
            '/cart/quotation'
          ).then((response) => {
            this.quotation = response.data;
            
          });
        });
      },
      deep: true,
      immediate: false,
    },

  }

}

// console.log('App')
</script>
<style>
/* @import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=MuseoModerno:wght@400&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=MuseoModerno:wght@400&family=IBM+Plex+Mono&family=Major+Mono+Display&family=Share+Tech+Mono&display=swap');

/* Google Chrome のスワイプによる戻るを無効化する */
* { overscroll-behavior-x: none ! important; } 

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #66b41e; */
  /* margin-top: 160px; */
}

.clock { 
  /* font-family: 'Share Tech Mono', monospace; */
  font-family: 'IBM Plex Mono', monospace;
  /* font-family: 'Major Mono Display', monospace; */
}

.clickable {
  cursor: pointer;
}

.unselectable {
  user-select: none;
}

[v-cloak] {
  display: none;
}


.accordion-body{
	display: grid; 
	grid-template-rows: 0fr;
	transition: 250ms grid-template-rows ease;
	/* background-color:#eee; */
}
.accordion-body.show {
	grid-template-rows: 1fr;
}
.accordion-body-inner {
	overflow:hidden;
}

/* .bd-highlight {
    background-color: rgba(86,61,124,0.15);
    border: 1px solid rgba(86,61,124,0.15);
} */

</style>
