import Vue from 'vue'
import Router from 'vue-router'

// import Login from './views/Login.vue'
// import Success from './views/Success.vue'
// import firebase from 'firebase/app'

Vue.use(Router)

// const router =  new Router({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes: [
//     {
//       path: '/',
//       name: 'login',
//       component: Login
//     },
//     {
//       path: '/success',
//       name: 'success',
//       component: Success
//     },
//     {
//       path: '*',
//       name: 'login',
//       component: Login
//     }
//   ]
// })

// Pages
// import Test from '@/components/Test.vue'
import Home from '@/components/Home.vue'
// import Task from '@/components/Task.vue'
// import Tasks from '@/components/Tasks.vue'
import Tasks from '@/components/Tasks.vue'
import Task from '@/components/Task.vue'
import MediaFiles from '@/components/MediaFiles.vue'
import TokutoriJP from '@/components/TokutoriJP.vue'

import Maintenance from '@/components/Maintenance.vue'
import HomeJa from '@/components/HomeJa.vue'
import HomeEn from '@/components/HomeEn.vue'
import HomeKo from '@/components/HomeKo.vue'
import HomeZh from '@/components/HomeZh.vue'
// import CheckoutCallback from '@/components/CheckoutCallback.vue'
// import Mojimoji from '@/components/Mojimoji.vue'


const router = new Router({
  mode: 'history',
  routes: [
    // {
    //   path: '/',
    //   name: 'home',
    //   component: Maintenance,
    // },
    {
      path: '/',
      name: 'home',
      component: HomeJa,
    },
    {
      path: '/ja',
      name: 'home_ja',
      component: HomeJa,
    },
    {
      path: '/en',
      name: 'home_en',
      component: HomeEn,
    },
    {
      path: '/ko',
      name: 'home_ko',
      component: HomeKo,
    },
    {
      path: '/zh',
      name: 'home_zh',
      component: HomeZh,
    },
    // {
    //   path: '/checkout_callback',
    //   name: 'checkout_callback',
    //   component: CheckoutCallback,
    // },
    {
      path: '/tokutori_jp',
      name: 'tokutori_jp',
      component: TokutoriJP,
    },
    {
      path: '/media_files',
      name: 'media_files',
      component: MediaFiles,
    },
    {
      path: '/media_files/:media_file_hashid/tasks',
      name: 'tasks',
      component: Tasks,
    },
    {
      path: '/media_files/:media_file_hashid/tasks/:task_hashid',
      name: 'task',
      component: Task,
    },

    // {
    //   path: '/media_files/:hashid/tasks',
    //   name: 'tasks',
    //   component: Tasks,
    // },
    // {
    //   path: '/test',
    //   name: 'test',
    //   component: Test,
    // },
    // {
    //   path: '/mojimoji',
    //   name: 'mojimoji',
    //   component: Mojimoji,
    // },
  ]
})

// router.beforeResolve((to, from, next) => {
//   console.log(to)
//   if (to.path == '/') {
//     next()
//   } else {
//     firebase.auth().onAuthStateChanged(user => {
//       if (user) {
//         console.log('認証中')
//         next()
//       } else {
//         console.log('未認証')
//         next({path: '/'})
//       }
//     })
//   }
// })

export default router