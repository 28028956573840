
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyBou6uLKFlxeqSQ1OLnT7G-mmcRyRm7I3E",
  authDomain: "ktb-tools.com",
  projectId: "valid-scene-323112",
  storageBucket: "valid-scene-323112.appspot.com",
  messagingSenderId: "270941956366",
  appId: "1:270941956366:web:30e88fd67e53e20839cf5f",
  measurementId: "G-QFE3ESYB1K"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

import { getAuth, signInWithPopup, signInWithRedirect, signOut, GoogleAuthProvider } from 'firebase/auth';

export const auth = getAuth();
// export const getAuth = getAuth;
// console.log('fugafuga', auth)
export const currentUser = auth.currentUser;
export const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'consent' }); // consent, none//20230818

export async function signInGoogle () {//20230818
  // var vm = this;
  return await signInWithPopup(auth, provider);//.then((result)=>{})
  // signInWithRedirect, signInWithPopup

  // .then((result)=>{
  //   console.log('result', result);
  //   console.log('auth', auth);
  // // signInWithRedirect(auth, provider).then(()=>{
  //   // console.log('auth', auth);
  //   console.log('provider', provider);
  //   console.log('accessToken', result.user.accessToken);
  //   window.localStorage.setItem('access_token', result.user.accessToken);
  //   // this.axios.put(
  //   //   '/auth', 
  //   //   { id_token: auth.currentUser.accessToken }
  //   // ).then((response) => {
  //   //   // console.log(response);
  //   //   // this.$router.push({ path: '/media_files', query: {} }).catch(err => {});
  //   //   window.location.href = '/media_files';
  //   // });
  // });
}


export async function signOutGoogle () {//20230818
  // var vm = this;
  return await signOut(auth); //.then((result)=>{
    // console.log(auth);
    // this.axios.delete(
    //   '/auth', 
    // ).then((response) => {
      // window.localStorage.setItem('access_token', null);
    //   // this.$router.push({ path: '/', query: {} }).catch(err => {});
    //   window.location.href = '/';

    // });
  // });
}
