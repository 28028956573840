<template>
  <div class="row">
    <div class="d-flex justify-content-center background">
      <div class="col-8 mt-5 pt-3">
        <div class="text-center sample"><h1><span style="background-color: #ddd;">您的 YouTube 视频跨越国界。</span></h1></div>
        <div class="text-center mt-5" style="background-color: #fff;"><h3>配备最新的人工智能。 轻松DIY翻译字幕！</h3></div>
        <div class="mt-5">
          <b>AI强大支持，轻松翻译字幕</b>
          <ul>
            <li>使用 pyannote.audio 进行高精度语音间隔检测。</li>
            <li>使用 OpenAI 的 Whisper 进行高度准确的转录。 </li>
            <li>由 DeepL SE 自然翻译成每种语言。</li>
          </ul>
        </div>
        <div class="mt-4">
          <b>价格合理</b>
          <ul>
            <li>载DIY字幕的VTT文件时付费。 </li>
            <li>口语部分（=字幕显示部分）的费用以秒为单位计算，每种语言每分钟 1.0 美元。</li>
          </ul>
        </div>
        <div class="text-center mt-5"><h4><span>让我们开始吧！</span></h4></div>
        <div class="text-center mt-4">

          <a class="text-dark clickable" v-if="auth.currentUser !== null">
            <b-button href="/media_files" variant="info">转到"项目"</b-button>
          </a>
          <a class="text-dark clickable" @click="signIn()" v-else>
            <button type="button" class="login-with-google-btn" >
              Sign in with Google
            </button>
          </a>
        </div>
      </div>
    </div>
   
  </div>
</template>

<script>

import {auth, signInGoogle} from '../myfirebase.js';

export default {
  name: 'Home',

  data () {
    return {
      auth: auth,//20230818
    }
  },
  methods: {

    signIn(){
      signInGoogle().then((result)=>{
        // console.log('successfully sign in.');

        window.localStorage.setItem('access_token', result.user.accessToken);
        this.axios.put(
          '/auth', 
          { id_token: result.user.accessToken }
        ).then((response) => {

          window.location.href = '/media_files';
        });
      });
    },
    
  },

  watch: {
  }
}
</script>

<style>
/* .fuchidori {
  color: #000;
  -webkit-text-stroke: 3px #FFF;
} */
.sample {
  color: #222;
  /* text-shadow: 2px 3px 5px #aaa; */
  transform: rotate(2deg);
}
.background {
  width: 100%;
  height: 1280px;
  background-image: url("../assets/translation-7774314_1920.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-color:rgba(255,255,255,0.8);
  background-blend-mode:lighten;
}

.login-with-google-btn {
  transition: background-color .3s, box-shadow .3s;
    
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
  
  /* &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }
  
  &:active {
    background-color: #eeeeee;
  }
  
  &:focus {
    outline: none;
    box-shadow: 
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px #c8dafc;
  }
  
  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
  } */
}
</style>