<template>
  <div class="row">
    <div class="d-flex justify-content-center background">
      <div class="col-8 mt-5 pt-3">
        <div class="text-center sample"><h1><span style="background-color: yellow; color: red;">ただいまメンテナンス中です！！！</span></h1></div>
        <div class="text-center sample2"><h1><span style="background-color: yellow; color: red;">UNDER MAINTENANCE NOW</span></h1></div>
        
        <!-- <button type="button" class="btn btn-info btn-lg btn-block mt-5"></button> -->
        <!-- <div class="mt-5">ユーザに必要な環境</div>
        <ul>
          <li>Googleのアカウントを所有していること。</li>
          <li>最新のモダンWEBブラウザ。</li>
          <li>1280x1080以上のモニター。</li>
        </ul> -->
      </div>
    </div>
    
  </div>
</template>

<script>

export default {
  name: 'Home',

  data () {
  },
  methods: {
    
  },

  watch: {
  }
}
</script>

<style>
/* .fuchidori {
  color: #000;
  -webkit-text-stroke: 3px #FFF;
} */
.sample {
  color: #222;
  /* text-shadow: 2px 3px 5px #aaa; */
  transform: rotate(2deg);
}
.sample2 {
  color: #222;
  /* text-shadow: 2px 3px 5px #aaa; */
  transform: rotate(-1deg);
}
.background {
  width: 100%;
  height: 90vh;
  background-image: url("../assets/translation-7774314_1920.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-color:rgba(255,255,255,0.8);
  background-blend-mode:lighten;
}

.login-with-google-btn {
  transition: background-color .3s, box-shadow .3s;
    
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
  
  /* &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }
  
  &:active {
    background-color: #eeeeee;
  }
  
  &:focus {
    outline: none;
    box-shadow: 
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px #c8dafc;
  }
  
  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
  } */
}
</style>