<template>

  <div>

    
    <div class="clickable" @dblclick="translate(true)"><small>{{text}}</small></div>

    <b-overlay
      :show="showOverlay" 
      spinner-variant="transparent"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div>
        <div class="text-center small">
          ↓
        </div>
        <div>
          <small>{{test.translation}}</small>
        </div>
        <div class="text-center small" v-if="!!test.translation_back">
          ↓
        </div>
        <div v-if="!!test.translation_back">
          <small>{{test.translation_back}}</small>
        </div>
      </div>
    </b-overlay>

  </div>
</template>

<script>
  import _ from 'lodash'
  
  export default {
    name: 'Sentence',
    data() {
      return {
        test: {},
        showOverlay: false,
      };
    },

    props: ['text', 'context', 'transcriptionLanguageCode', 'pilotTranslationLanguageCode', 'back'],
    
    methods: {
      
      translate: function (ignore_cache = false){
        
        this.showOverlay = true;
        const reIgnore = /[\t\n\r\\]/gi;
        const text = this.text ? this.text.replace(reIgnore, '') : '';
        const context = this.context ? this.context.replace(reIgnore, '') : '';

        const params = {
          'language_code_src': this.transcriptionLanguageCode,
          'language_code_tgt': this.pilotTranslationLanguageCode,
          'text': text,
          'context': context,
          // 'contextual_words': this.contextual_words,
          'ignore_cache': ignore_cache ? 1 : 0,
          'back': this.back,
        };
        // console.log(params);
        this.axios.post(
          '/translate_test', 
          params
        ).then((response) => {
          
          const data = response.data;
          if(text === data.text && this.pilotTranslationLanguageCode === data.language_code_tgt){
            this.test = response.data;
          }
          
          this.showOverlay = false;
        });
      },

    },
    computed: {

    },
    
    created () {

    },
    mounted () {
        this.translate();
    },
    watch: {
      
      'text': {
        handler: _.debounce(function (text, text_old) {
          // console.log('watch!sentence!', text, text_old);
          this.translate();
        }, 2800),
        deep: true,
        immediate: true,
      },

    },
    filters: {
    },
  }

</script>

<style scoped>
  
</style>