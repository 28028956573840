<template>
  <header>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item d-flex align-items-center">
            <router-link class="brand" to="/">KTB-Tools</router-link>
          </li>
          <li class="nav-item d-flex align-items-center pl-2 pt-2">
            {{$t('DIY Translation Subs')}}
          </li>

          <!-- <li class="nav-item d-flex align-items-center">
            <router-link class="text-dark px-3" to="/media_files">Media Files</router-link>
          </li> -->
          <li class="nav-item d-flex align-items-center">
            <!-- {{$store.state.message}} -->
          </li>
          
        </ul>
        <ul class="navbar-nav">

          <!-- <li class="nav-item px-2 pt-1">
            <b-icon icon="list-task" aria-hidden="true" font-scale="1.45"></b-icon>
          </li> -->
          <li class="nav-item pt-1 pr-3" v-if="auth.currentUser !== null">
             <b-link to="/media_files" class="b-link"><u>{{ $t('Projects') }}</u></b-link>
          </li>
          <!-- <li class="nav-item px-1 pt-1">
            <b-icon icon="globe2" aria-hidden="true" font-scale="1.45"></b-icon>
          </li>
          <li class="nav-item">
            <b-form-select v-model="$i18n.locale" :options="localeOptions" size="sm" ></b-form-select>
          </li> -->
        </ul>

        <ul class="navbar-nav" v-if="auth">
          <li class="nav-item px-3 pt-1" v-if="auth.currentUser !== null">
            {{auth.currentUser.displayName}}
          </li>
          <li class="nav-item pt-1">
            <a class="text-dark clickable" @click="signOut()" v-if="auth.currentUser !== null">
              <i class="material-icons">&#xe9ba;</i>
            </a>
            <a class="text-dark clickable" @click="signIn()" v-else>
              <b-img :src="require('../assets/sign_in/btn_google_signin_light_normal_web@2x.png')" width="142"></b-img>
            </a>
          </li>
          <li class="nav-item pl-3 pt-1" v-if="auth.currentUser !== null">
            <a class="text-dark clickable" @click="$emit('toggleCart')">
              <b-icon icon="cart" aria-hidden="true" font-scale="1.45"></b-icon><small class="clock">{{task_hashidsInCart.length}}</small>
            </a>
          </li>
        </ul>

      </div>
    </nav>
  </header>
  <!-- <div class="fixed-top">...</div> -->
</template>

<script>
import _ from 'lodash';

import {auth, signInGoogle, signOutGoogle} from '../myfirebase.js';


export default {
  name: 'Header',

  data () {
    return {
      
      localeOptions: [
        {
          value: 'ja',
          text: '日本語',
        },
        {
          value: 'en',
          text: 'English',
        },
        {
          value: 'zh',
          text: '中文',
        },
        {
          value: 'ko',
          text: '한국의',
        },
      ],
      auth: auth,//20230818
    }
  },

  props: {
    task_hashidsInCart: {
      required: false, // @todo ???
      type: Array
    },
    
  },
  created() {

  },
  methods: {

    signIn(){
      signInGoogle().then((result)=>{
        // console.log('successfully sign in.');
        
        window.localStorage.setItem('access_token', result.user.accessToken);
        this.axios.put(
          '/auth', 
          { id_token: result.user.accessToken }
        ).then((response) => {

          window.location.href = '/media_files';
        });
      });
    },
    signOut(){
      signOutGoogle().then(()=>{
        console.log('successfully sign out.');
        // console.log('access_token', result.user.accessToken);
        window.localStorage.setItem('access_token', null);
        window.location.href = '/';

      });
    },
    
  },

  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Rammetto+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cherry+Bomb+One&family=Rammetto+One&display=swap');
.brand {
  /* font-family: 'MuseoModerno', cursive; */
  /* font-family: 'Rammetto One', cursive; */
  /* font-family: 'Rammetto One', cursive; */
  font-family: 'Cherry Bomb One', cursive;
  /* color: orangered; */
  color: #F73900;
  font-size: 1.7rem;
  /* text-shadow: 5px 6px 0 yellow; */
}
.brand:hover {
  /* font-size: 1.6rem; */
  text-decoration: none;
}
/* b-link {
  

} */
a.b-link:link { color: #000; }
a.b-link:visited { color: #000; }
a.b-link:hover { color: #000; }
a.b-link:active { color: #000; }

</style>