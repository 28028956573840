<template>
  <div class="mb-3 accordion-body">
    <div class="row">
    	<div class="col-md-12 small">
    		<div class="panel panel-default">
    			<div class="panel-body">
					<div class="row my-1">
						<div class="col-9 text-right mt-1">
							<div v-if="quotation.coupon">
								<span v-if="quotation.coupon_available">{{ quotation.coupon.description }}
									(<span class="clock">{{ new Date(quotation.coupon.expired_at ) | date('yyyy-MM-dd HH:mm')}}</span> まで有効) 
								</span> 
								<span class="text-danger" v-else>無効なクーポンです! </span> 
								<span v-if="quotation.coupon" :class="{'text-success': quotation.coupon_available, 'text-danger': !quotation.coupon_available}"> <b-icon icon="check-lg" v-if="quotation.coupon_available"></b-icon> 
									{{ quotation.coupon?.code }} <span class="clickable" @click="setCoupon()"><b-icon icon="trash"></b-icon></span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<b-input-group size="sm">
								<b-form-input trim type="text" v-model="coupon_code" placeholder="クーポンコード"></b-form-input>
								<b-input-group-append>
								<b-button variant="outline-secondary" @click="setCoupon(coupon_code)" :disabled="coupon_code.length==0">適用</b-button>
								</b-input-group-append>
							</b-input-group>
						</div>
					</div>
    				<div class="table-responsive">
    					<table class="table table-sm table-condensed">
    						<thead>
								<tr>
									<td class="thick-line" colspan="2">字幕</td>
									<td class="text-right thick-line" width="10%">単価(円)</td>
									<td class="text-right thick-line" width="5%"></td>
								</tr>
    						</thead>
    						<tbody>
								<tr v-for="task in cart" v-bind:key="task.hashid">
    								<td colspan="2" class="align-middle"><b-link :href="`/media_files/${task.media_file.hashid}/tasks?co=1`">{{ task.media_file.info.title }} - {{ task.media_file.info.channel }}</b-link> - {{task.language_code_deepl}} {{ $t('lang.'+task.language_code_deepl) }}</td>
    								<td class="text-right align-middle">
										<span :class="{'strikethrough': task.fee.total_discounted !== task.fee.total}">{{ Number(task.fee.total).toLocaleString() }}</span>
										<span v-if="task.fee.total_discounted !== task.fee.total"> {{ Number(task.fee.total_discounted).toLocaleString() }}</span>
									</td>
    								<td class="text-center align-middle" style="font-size: 1.12rem;"><span class="clickable" @click="$emit('removeCart', task.hashid)"><b-icon icon="cart-dash" aria-hidden="true"></b-icon></span></td>
    							</tr>
    							<tr>
    								<td class="thick-line text-right" colspan="2">
										計
									</td>
    								<td class="thick-line text-right">{{ Number(quotation.total).toLocaleString() }}</td>
    								<td class="thick-line"></td>
    							</tr>
    						</tbody>
    					</table>
    				</div>
    			</div>
    		</div>
    	</div>
    </div>
	<div v-if="quotation.total!=0">
		<b-button block variant="outline-primary" size="md" @click="checkout()" :disabled="!(quotation.total>=50)"><b-icon icon="credit-card"></b-icon> {{ $t('Pay_now')}} <small class="text-danger" v-if="!(quotation.total>=50)">(50円未満の決済はできません)</small></b-button>
	</div>
	<div v-else>
		<b-button block variant="outline-success" size="md" @click="checkout()"> {{ $t('Pay_now')}} (特別に0円で決済を通します)</b-button>
	</div>
  </div>
</template>

<script>
export default {
  name: 'Cart',

  data () {
    return {
		coupon_code: '',
    }
  },

  props: {
    cart: {
      required: true, // @todo ???
      type: Array
    },
    quotation: {
      required: true, // @todo ???
      type: Object
    },
  },
  created() {

  },
  methods: {
	setCoupon: function(coupon_code){
      this.axios.post(
        '/cart/set_coupon', 
        {
          'coupon_code': coupon_code,
        }
      ).then((response) => {
		this.$emit('loadCart');
      });
    },
    checkout: function(){
      this.axios.post(
        '/cart/checkout', 
        {
          'success_url': location.href,
          'cancel_url': location.href+'?co=1',
        }
      ).then((response) => {
        // window.alert(response.data.session_url);
        window.location.href = response.data.session_url;
      });
    },
  },

  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.strikethrough{
	text-decoration: line-through;
}

table {
  color: #555;
  font-size: 0.87rem;
}
.invoice-title h2, .invoice-title h3 {
    display: inline-block;
}

.table > tbody > tr > .no-line {
    border-top: none;
}

.table > thead > tr > .no-line {
    border-bottom: none;
}

.table > tbody > tr > .thick-line {
    border-top: 2px solid #ccc;
}
.table > thead > tr > .thick-line {
    border-top: 2px solid #ccc;
}



/* .v-enter-active, .v-leave-active {
  transition: opacity .5s;
}  */
/*遷移に0.5秒かける*/
/* 
.v-enter, .v-leave-to{
  opacity: 0;
}  */
/*現れる時の最初と消える時の最後のopacityを0に指定*/


</style>