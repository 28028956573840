<template>
  <div>

    <!-- subtitle.text -->
    <div class="d-flex justify-content-between bd-highlight">
      <div class="bd-highlight">
        <small class="align-bottom subheading">{{ $t(task.demand) }}: <span class="clock">{{task.language_code_deepl}}<!--{{ $t('lang.'+settings.transcriptionLanguageCode) }}--></span></small>
        <b-button variant="link" size="sm" v-b-tooltip.hover.right title="話者を変更する" :id="'popover-radio-'+index0" style="margin-bottom: -6px" v-if="false && media_file.seg_model!=='pyannote/speaker-segmentation'">
          <span :class="'speaker-'+subtitle.speaker" v-if="subtitle.speaker < 20"><i class="bi bi-person-fill"></i>{{ settings.speakerCnames[subtitle.speaker] ? settings.speakerCnames[subtitle.speaker] : subtitle.speaker }}</span>
          <!-- <span :class="'telop-'+subtitle.speaker" v-else><i class="bi bi-card-text"></i> Telop {{ subtitle.speaker }}</span> -->
        </b-button>
      </div>
      <div class="bd-highlight">

        <!-- <b-popover :target="'popover-radio-'+index0" triggers="click">
          <div class="d-flex justify-content-between bd-highlight">
            <div>
              <b-form-group>
                <b-form-radio class="bd-highlight" style="height: 25px" v-for="(cd, idx) in settings.speakerCnames" v-model="subtitle.speaker" :value="idx" size="sm" :key="idx" button-variant="outline-secondary">
                  <span class="bd-highlight" :class="'speaker-'+idx" v-if="idx < 20"><i class="bi bi-person-fill"></i>{{ settings.speakerCnames[idx] ? settings.speakerCnames[idx] : idx }}</span>
                  <span class="bd-highlight" :class="'telop-'+idx" v-else><i class="bi bi-card-text"></i> Telop {{ idx }}</span>
                </b-form-radio>
              </b-form-group>
            </div>
            <div>
              <div v-for="(cd, idx) in settings.speakerCnames" :key="idx" class="bd-highlight align-top" style="height: 25px">
                &nbsp; &nbsp; &nbsp; &nbsp;
                <button class="btn btn-link btn-sm pencil" :id="'popover-edit-'+index0+'-'+idx" style="margin-top: -6px">
                  <small><i class="bi bi-pencil"></i></small>
                </button>
                <b-popover :target="'popover-edit-'+index0+'-'+idx" triggers="click blur">
                  <div class="d-flex justify-content-between">
                    <b-form-input size="sm" v-model="settings.speakerCnames[idx]" :placeholder="idx" v-b-tooltip.hover.right title="別名を付けることができます。Enterで確定。"></b-form-input>
                  </div>
                </b-popover>
              </div>
            </div>
          </div>
        </b-popover> -->

      </div>
      <div
      class="clickable unselectable animeS bd-highlight" 
      v-b-tooltip.hover.right title="この字幕セグメントをAIで文字起こしします。"
      @click="transcribe()"
      >
        <small><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-ear" viewBox="0 0 16 16">
          <path d="M8.5 1A4.5 4.5 0 0 0 4 5.5v7.047a2.453 2.453 0 0 0 4.75.861l.512-1.363a5.553 5.553 0 0 1 .816-1.46l2.008-2.581A4.34 4.34 0 0 0 8.66 1H8.5ZM3 5.5A5.5 5.5 0 0 1 8.5 0h.16a5.34 5.34 0 0 1 4.215 8.618l-2.008 2.581a4.555 4.555 0 0 0-.67 1.197l-.51 1.363A3.453 3.453 0 0 1 3 12.547V5.5ZM8.5 4A1.5 1.5 0 0 0 7 5.5v2.695c.112-.06.223-.123.332-.192.327-.208.577-.44.72-.727a.5.5 0 1 1 .895.448c-.256.513-.673.865-1.079 1.123A8.538 8.538 0 0 1 7 9.313V11.5a.5.5 0 0 1-1 0v-6a2.5 2.5 0 0 1 5 0V6a.5.5 0 0 1-1 0v-.5A1.5 1.5 0 0 0 8.5 4Z"/>
        </svg> {{ $t('transcribe') }}</small>
      </div>
    </div>
    <b-overlay
      :show="showOverlay" 
      spinner-variant="secondary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >

      <b-form-textarea 
      v-model="subtitle.text" ref="subtitleText" :stkey="subtitle.start"
      class="form-control text" 
      :class="{'bold': !(subtitle.text_ex)}" 
      :state="subtitle.okState(settings.charLimitPerSegment, settings.speedLimitPerSec)" 
      placeholder="文字起こしテキスト"
      @keyup.shift.enter.exact.prevent="splitSegmentEmit(subtitle, $event)"
      @keyup.shift.delete.exact.prevent="joinSegmentsEmit(subtitle, $event)"
      trim
      row="1"
      :max-rows="maxRows"
      size="sm"
      v-b-tooltip.hover.right title="文字起こしテキストを入力します。"
      >
      </b-form-textarea>
    </b-overlay>

    <div class="clock text-right bd-highlight">
      <div class="bd-highlight">
        <small :class="{'text-danger': !subtitle.okStateCharLimitPerSegment(settings.charLimitPerSegment)}">{{subtitle.text.length}}{{$t('characters')}}</small> <small v-b-tooltip.hover.left title="字幕スピード" :class="{'text-danger': !subtitle.okStateSpeedLimitPerSec(settings.speedLimitPerSec)}">{{Math.round(subtitle.text.length/(subtitle.end-subtitle.start)*100)/100}}{{$t('characters')}}/{{$t('seconds')}}</small>
      </div>
    </div>
    <!-- /subtitle.text -->

    <!-- subtitle.text_ex -->
    <div>
      <div class="d-flex justify-content-between bd-highlight">
        <div class="bd-highlight"><small class="subheading">{{ $t('Revised_Transcription_for_Translation') }}: <span class="clock">{{task.language_code_deepl}}<!--{{ $t('lang.'+settings.transcriptionLanguageCode) }}--></span></small></div>
        <!-- <div class="bd-highlight"><small>{{ task.language.name_en }} post-transcription</small></div> -->
        <div
        class="clickable unselectable animeM bd-highlight" 
        v-b-tooltip.hover.right title="コピペします"
        @click="copipe()"
        >
          <small><b-icon icon="arrow-down"></b-icon>{{ $t('copy') }}</small>
        </div>
      </div>      
      <b-form-textarea 
      v-model="subtitle.text_ex" ref="subtitleTextEx"
      class="form-control text-ex"
      :class="{'bold': subtitle.text_ex}" 
      trim
      row="1"
      :max-rows="maxRows"
      size="sm"
      @keydown.shift.enter.exact.prevent
      :placeholder="subtitle.text"
      v-b-tooltip.hover.right 
      title="翻訳用に文字起こしテキストに補正を施します。例えば、主語や目的語を補ったり、一般的でない略語を戻したり、言い回しを変更したり、不要なフィラーを取り除いたりです。未入力の場合は文字起こしテキストに準じます。何が入力されていても字幕としては出力されません（出力できません）。"
      >
      <!-- @keydown.enter.exact.prevent -->
      </b-form-textarea>
    </div>
    <!-- /subtitle.text_ex -->




    <!-- <div v-if="subtitle.test"> 
      <table class="table mt-2">
        <tbody style="font-size: 0.9rem;">
          <tr>
            <td>{{ subtitle.test.language_code_tgt }}</td>
            <td>{{ subtitle.test.translation }}</td>
          </tr>
        </tbody>
      </table>
      <div class="mt-1 text-center">
        <div>
          <b-icon class="translate"></b-icon>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-translate" viewBox="0 0 16 16">
            <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z"/>
            <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z"/>
          </svg> <span class="small clock">AIリバース翻訳</span>
        </div>
      </div>
      <table class="table mt-2" v-b-tooltip.hover.left title="上のAI翻訳を元に、元の言語に翻訳したものです。原文の文意と同等になれば、原文は翻訳に向いていると言えます。">
        <tbody style="font-size: 0.9rem;">
          <tr>
            <td>{{ subtitle.test.language_code_src }}</td>
            <td>{{ subtitle.test.translation_back }}</td>
          </tr>
        </tbody>
      </table>
    </div> -->
    <!-- TranscriptionSubtitleEditor -->
  </div>
</template>

<script>
  // export default Vue.observable({
  //   sample: { },
  //   // foo: { },
  //   // bar: { },
  // })
  // import BigNumber  from "bignumber.js";

  // import Subtitle from '../subtitle.js'
  
  export default {
    name: 'TranscriptionSubtitleEditor',
    data() {
      return {
        showOverlay: false,
      };
    },

    // _props: ['task', 'previousTask', 'settings', 'subtitle', 'media_file', 'index0'],
    props: {
      'task': {
        type: Object,
        required: true,
      },
      // 'previousTask': {
      //   type: Object,
      //   required: true,
      // },
      'settings': {
        type: Object,
        required: true,
      },
      'subtitle': {
        type: Object,
        required: true,
      },
      'media_file': {
        type: Object,
        required: true,
      },
      'index0': {
        type: Number,
        required: true,
      },
    },
    
    methods: {
      
      splitSegmentEmit: function (subtitle, event) {
        console.log(event);
        const caretPos = event.target.selectionStart;
        this.$emit("splitSegment", caretPos);
      },
      joinSegmentsEmit: function (subtitle, event) {
        const caretPos = event.target.selectionStart;
        this.$emit("joinSegments", caretPos);
      },

      copipe: function(){
        // const regexp = new RegExp(`\[[${this.settings.fullStop}]\]`, 'gm');
        // const regexp = new RegExp(`[]`, 'gm');
        this.subtitle.text_ex = this.subtitle.text//.replace(/\[([${this.settings.fullStop}])\]/g, '$1');
      },

      
      transcribe: function (){
        this.showOverlay = true;
        this.axios.post(
          '/transcribe', 
          {
            'media_file_hashid': this.media_file.hashid,
            'start': this.subtitle.start,
            'end': this.subtitle.end,
            'language_code': this.task.language_code_deepl,
          }
        ).then((response) => {
          // this.subtitle.text = response.data.transcript;
          this.subtitle.text = response.data.text;
          this.showOverlay = false;
        });
      },

    },
    computed: {
      maxRows: function(){
        return Math.ceil((this.settings.charLimitPerSegment + 1)/23.0);
      },
    },
    created () {
      // console.log(this.$route.params.hashid);

    },
    mounted () {

    },
    watch: {
    },
    filters: {
    },
  }

</script>

<style scoped>
  ::placeholder {
    color: #aaa;
  }
  textarea.bold{
    font-weight: bolder;
  }


  /* .speaker-0{
    color: grey;
  } */
/* 
  .telop-L{
    color: magenta;
  }
  .telop-M{
    color: magenta;
  }
  .telop-S{
    color: magenta;
  }
  .pencil{
    color: silver;
  } */
  .pencil:hover{
    color: #333;
  }

</style>